import { useCookieUniversal } from '~/composables/use-cookie-universal';
import { useRequestIp } from '~/composables/use-request-ip';
import { cookieNames, getExpirationDateBySeconds } from '~/utils/cookies';

/**
 * Creates a cookie to track changes to the user's IP address.
 *
 * When the cookie's IP address has changed, the store's `user/saveLoginLocation`
 * action will save the login location of the user when they're authenticated.
 */
export default defineNuxtRouteMiddleware(() => {
  if (process.client) {
    return;
  }

  const ip = useRequestIp();

  if (!ip) {
    return;
  }

  const cookies = useCookieUniversal();
  const locationCookie = cookies.get(cookieNames.location);

  if (!locationCookie || locationCookie.ip !== ip) {
    const oneHourFromNow = getExpirationDateBySeconds(3600);
    cookies.set(cookieNames.location, {
      ip,
      changed: true,
    }, {
      expires: oneHourFromNow,
      path: '/',
      sameSite: 'Lax'
    });
  }
});
