import { useFeathers } from '~/composables/use-feathers';

type ServicePath =
  'api/v1/apple-auth' |
  'api/v1/bexrealty-account-page' |
  'api/v1/bexrealty-advertisement' |
  'api/v1/bexrealty-agent-page' |
  'api/v1/bexrealty-agent-profile-page' |
  'api/v1/bexrealty-calculator-page' |
  'api/v1/bexrealty-campaign-survey-page' |
  'api/v1/bexrealty-community-page' |
  'api/v1/bexrealty-community-map-page' |
  'api/v1/bexrealty-comp-page' |
  'api/v1/bexrealty-complete-registration' |
  'api/v1/bexrealty-homepage' |
  'api/v1/bexrealty-favorite-listing' |
  'api/v1/bexrealty-favorite-listings-page' |
  'api/v1/bexrealty-lead-programs-page' |
  'api/v1/bexrealty-listing-page' |
  'api/v1/bexrealty-new-listing-page' |
  'api/v1/bexrealty-recent-community' |
  'api/v1/bexrealty-recently-viewed-page' |
  'api/v1/bexrealty-saved-search-page' |
  'api/v1/bexrealty-saved-search' |
  'api/v1/bexrealty-sitemap-page' |
  'api/v1/bexrealty-sitemap-zipcode-page' |
  'api/v1/bexrealty-state-page' |
  'api/v1/bexrealty-url-generator' |
  'api/v1/bexrealty-zipcode-landing-page' |
  'api/v1/bexrealty-zipcode-page' |
  'api/v1/bexrealty-zipcode-map-page' |
  'api/v1/campaign-survey' |
  'api/v1/community-menus' |
  'api/v1/contact-share-listing' |
  'api/v1/contact-lead-sales' |
  'api/v1/contact-us' |
  'api/v1/lead-inquiry-contact' |
  'api/v1/legacy-agents' |
  'api/v1/legacy-password-forgot' |
  'api/v1/legacy-login-locations' |
  'api/v1/legacy-password-reset' |
  'api/v1/legacy-recent-properties' |
  'api/v1/legacy-saved-properties' |
  'api/v1/new-lead-alert' |
  'api/v1/omni-search' |
  'api/v1/property-listings' |
  'api/v1/register' |
  'api/v1/users';

export function useFeathersService(path: ServicePath) {
  return useFeathers().service(path);
}
