<template>
  <NuxtLayout>
    <div class="container">
      <div class="error-number">
        {{ error.statusCode }}
      </div>

      <div v-if="error.statusCode === 404" class="error-message">
        Sorry, but we can't seem to find the page you're&nbsp;looking&nbsp;for.
      </div>

      <div v-else class="error-message">
        Something went wrong when trying to load this page.<br>Please refresh the page to try again.
        <button @click="reloadPage()">Refresh Page</button>
      </div>

      <div
        v-if="hasCurrentState"
        class="error-grid">
        <a :href="stateUrl(currentState.canonical_path)" class="btn">
          {{ currentState.name }} Homes for {{ saleOrRent }}
        </a>
      </div>

      <div
        class="error-grid"
        v-if="error.statusCode === 404"
      >
        <div class="error-message">
          <div class="bex-fleur-error"></div>
          See homes for {{ saleOrRent }} in these other&nbsp;states:
        </div>
        <div class="other-states" :class="{ columns: !hasCurrentState }">
          <div v-for="(states, index) in otherStates" :key="index" class="other-states-container">
            <a :href="stateUrl(states.canonical_path)" class="state-links error-links">
              {{ states.name }} Homes for {{ saleOrRent }}
            </a>
          </div>
        </div>
      </div>
      <div v-if="eventId" class="error-grid bug-report">
          <div class="error-message"><strong>Want to help us fix this problem?</strong><br />Please supply us details so we can prevent you from seeing this page in the future.</div>
          <button @click="submitAdditionalInformation()" class="btn">Submit Crash Report</button>
      </div>
    </div>
  </NuxtLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { isListingRentalPage, isCommunityRentalPage, isStateRentalPage, pathConstants } from '~/utils/route-utils';
import { stateNameMap } from '~/utils/state-name-map';

import { httpStatusCodes } from '~/errors/http-error';

export default defineComponent({
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup() {
    const route = useRoute();
    const stateName = stateNameMap[route.params.state] ?? '';
    const isRentalPage = isCommunityRentalPage(route) || isListingRentalPage(route) || isStateRentalPage(route);
    const saleOrRent = isRentalPage ? 'rent' : 'sale';

    if (stateName) {
      useGenericHead({
        title: 'Page Not Found - BEX Realty - ' + stateName + ' Real Estate',
        description: `${stateName} Real Estate. ${stateName} homes for ${saleOrRent} presented by BEX Realty, the leader in ${stateName} real estate.`,
      });
    } else {
      useGenericHead({
        title: 'Page Not Found - BEX Realty',
        description: `Real estate and homes for ${saleOrRent} - presented by BEX Realty, the leader in real estate.`,
      });
    }

    return {
      isRentalPage,
      saleOrRent,
    };
  },
  data: () => ({
    eventId: null
  }),
  computed: {
    // Map the menus from the Vuex state object
    ...mapGetters(['listingsByStateMenu']),

    currentState() {
      const currentStateName = this.$route.params.state;
      return this.listingsByStateMenu.find((state) => state.name === currentStateName) ?? null;
    },
    hasCurrentState () {
      return this.currentState !== null;
    },
    otherStates() {
      const currentStateName = this.$route.params.state;
      return this.listingsByStateMenu.filter((state) => state.name !== currentStateName) ?? [];
    },
    linkUrl() {
      return process.env.wwwBaseUrl + this.$route.fullPath;
    },
  },
  methods: {
    reloadPage(){
      window.location.reload()
    },
    stateUrl(url) {
      return this.isRentalPage ? `${url}${pathConstants.communityRental}` : url;
    },
    submitAdditionalInformation() {
      // Show the user feedback modal
      this.$sentry?.showReportDialog({ eventId: this.eventId });
    },
  },
  mounted () {
    // 404 errors don't need to be logged to sentry
    if (this.error?.statusCode === httpStatusCodes.NotFound) {
      return;
    }

    // Capture the event
    this.eventId = this.$sentry?.captureException(this.error);
  }
});
</script>

<style scoped lang="less">
.bex-fleur-error {
  background: url('~/assets/icons/bex_icons.gif') -215px 4px;
  background-size: 320px;
  width: 42px;
  height: 42px;
  margin: 25px auto 5px auto;
}

.other-states {
  column-count: 4;
  max-width: 1500px;
  margin: auto;
  @media screen and (max-width: 1200px) {
    column-count: 3;
  }
  @media screen and (max-width: 900px) {
    column-count: 2;
  }
  @media screen and (max-width: 600px) {
    column-count: 1;
  }
  &-container {
    margin: 0 12px 12px 12px;
  }
  &.columns {
    column-count: 4;
    @media screen and (max-width: 936px) {
      column-count: 2;
    }
    @media screen and (max-width: 600px) {
      column-count: 1;
    }
  }
}

a {
  &.state-links.error-links {
    color: @bex-blue;
    text-decoration: none;
    font-weight: 600;
    margin: auto;
    text-transform: capitalize;
  }
  &.btn {
    text-transform: capitalize;
  }
}

.nav-search-grid,
.nav-border {
  border-bottom: 1px solid @light-gray;
}

.search-spacing {
  padding: 0 15px;
  @media screen and (max-width: 936px) {
    padding: 60px 15px 0 15px;
  }
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px 20px 50px 20px;
  min-height: calc(100vh - 256px);
  text-align: center;
  @media screen and (max-width: 936px) {
    min-height: calc(100vh - 186px);
  }
  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 442px);
  }
}

.error {
  &-number {
    font-family: @heading-font;
    font-size: 92px;
    color: @bex-blue;
  }
  &-message {
    font-size: 18px;
    color: #7d7d7d;
    & button {
      margin: 25px auto;
    }
    @media screen and (max-width: 640px) {
      grid: auto auto e("/") auto;
      max-width: auto;
    }
  }
  &-grid {
    display: grid;
    grid: auto e("/") auto;
    grid-gap: 20px;
    margin: 25px auto 0 auto;
    justify-content: center;
    @media screen and (max-width: 640px) {
      grid: auto auto e("/") auto;
      max-width: auto;
    }
    &.bug-report {
      margin-top: 75px;
      & button {
        margin: auto;
      }
    }
  }
}
</style>
