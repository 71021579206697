import FeathersService from '~/services/base/feathers-service';

export class UserService extends FeathersService {
  /**
   * Get user
   * @param {String} userId
   * @param {RequestTimer} requestTimer
   */
  async getUser(userId, requestTimer) {
    return await super.throwableRequest(async (baseQuery) => {
      const result = await this.service.get(userId, {
        query: {
          ...baseQuery,
        },
      });

      return result;
    }, 'getUser', requestTimer)
  }
}
