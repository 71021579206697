import FeathersService from '~/services/base/feathers-service';

export class ContactLeadSalesService extends FeathersService {
  /**
   * sends an email to a distro about a potential client inquiry
   * @param {Object} formData
   * @param {String} listingUrl the url where the form was submitted from
   * @param {RequestTimer} requestTimer
   * @param {Object} $sentry sentry instance
   */
  async sendContactMessage(formData, requestTimer, $sentry) {
    return await super.throwableRequest(async (baseQuery) => {
      await this.service.create({
        ...formData,
      }, {
        query: {
          ...baseQuery,
        },
      });
    }, 'sendContactMessage', requestTimer, null, $sentry);
  }
}
