export function useCanonicalPath() {
  const route = useRoute();

  let canonicalPath = process.env.wwwBaseUrl;

  if (route.name !== 'index') {
    canonicalPath += route.fullPath;
  }

  return canonicalPath;
}
