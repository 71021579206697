import { v4 as uuidv4 } from 'uuid';

import { getClientIp } from '~/utils/http';


/**
 * generates unique labels used to print request timing information on bexrealty.com as well as bex-api
 */
export class RequestTimer {
  constructor(label, request, route) {
    this.now = (new Date()).toJSON();

    this.label = label;

    this.ip = getClientIp(request?.headers);

    if (!route) {
      this.hasRouteInfo = false;
      this.routeName = null;
      this.fullPath = null;
    } else {
      this.hasRouteInfo = true;
      this.routeName = route.name;
      this.fullPath = route.fullPath;
    }
  }

  /**
   * start a timer
   */
  start() {
    if (process.server) {
      const label = this.toString();
      console.time(label);
    }
  }

  /**
   * stops the timer and prints timing information to stdout
   */
  stop() {
    if (process.server) {
      const label = this.toString();
      console.timeEnd(label);
    }
  }

  /**
   * string representation of the RequestTimer
   */
  toString() {
    let stringified;
    if (this.hasRouteInfo) {
      stringified = `${this.now} [${this.ip}] ${this.routeName}-${this.label} ${this.fullPath}`;
    } else {
      stringified = `${this.now} [${this.ip}] ${this.label}`;
    }
    return stringified;
  }

  /**
   * gets a label for logging in the api
   * @param {String} context a string to uniquely identify a service call to api
   */
  getServiceLabel(context) {
    let timeLabel;
    if (this.hasRouteInfo) {
      timeLabel = `${this.now} [${this.ip}] ${this.routeName}-${this.label} ${this.label}.${context} ${this.fullPath}`;
    } else {
      timeLabel = `${this.now} [${this.ip}] ${this.label}.${context}`
    }
    return timeLabel;
  }

  getServiceBaseQuery(context) {
    const requestId = uuidv4();
    const timeLabel = this.getServiceLabel(context);
    return {
      $requestId: requestId,
      $timeLabel: timeLabel,
    };
  }
}
