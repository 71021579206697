import { useStore } from '~/composables/use-store';
import { useRequestUserAgent } from '~/composables/use-request-user-agent';
import { actionTypes, authMethods } from '~/utils/analytics';

/**
 * This plugin injects the appleAuth object into the context. This object adds the Apple Auth functionality to the app
 * by loading the Apple Auth script and initializing the Apple Auth object.
 */
export default defineNuxtPlugin({
  parallel: true,
  setup() {
    /**
     * Add the Apple Auth script to the page and initialize the Apple Auth object when the script is loaded.
     */
    function addScript() {
      try {
        const script = document.createElement('script');
        script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
        script.async = true;
        script.defer = true;
        script.onload = init;
        document.head.appendChild(script);
      } catch (e) {
        logErrorToSentry('addScript', e);
      }
    }

    const redirectURI = window.location.origin;

    /**
     * Initialize the Apple Auth object using the client ID
     */
    function init() {
      const appleAuthClientId = process.env.appleAuthClientId;

      // eslint-disable-next-line no-undef
      AppleID.auth.init({
        clientId : appleAuthClientId,
        scope : 'name email',
        redirectURI,
        usePopup : true,
      });
    }

    async function signIn() {
      const store = useStore();
      const nuxtApp = useNuxtApp();
      const userAgent = useRequestUserAgent();

      try {
        // eslint-disable-next-line no-undef
        const response = await AppleID.auth.signIn();

        if (!response) {
          throw new Error('Apple login failed to return a response');
        }

        store.dispatch('loading/start');

        await store.dispatch('user/authenticate', {
          strategy: 'apple',
          code: response.authorization?.code,
          name: response.user?.name,
          conversionPage: window.location.href,
          redirectURI,
          platform: 'web',
          userAgent,
        });

        // send a CTA event that auth was successful
        nuxtApp.$bexGtag.sendCtaEvent({
          id: authMethods.appleButton,
          actionType: actionTypes.success,
        });

        // send a recommended GA event that auth was successful
        nuxtApp.$bexGtag.sendLoginEvent({
          method: authMethods.appleButton,
        });
      } catch (e) {
        logErrorToSentry('signIn', e);
      } finally {
        store.dispatch('loading/finish');
        store.dispatch('modal/close');
      }
    }

    /**
     * Takes the function name and the error object and logs it to Sentry
     */
    function logErrorToSentry(functionName, error) {
      if (process.env.logDebug) {
        console.error(`AppleAuth::${functionName}`, error);
      } else {
        const errorToLog = new AppleAuthError(`AppleAuth::${functionName}`, error);
        useNuxtApp().$sentry?.captureException(errorToLog);
      }
    }

    return {
      provide: {
        appleAuth: {
          addScript,
          signIn,
        },
      },
    };
  },
});

class AppleAuthError extends Error {
  constructor(message, error) {
    super(`${message}: ${error.message}`);
    this.name = 'AppleAuthError';
    this.cause = error;
  }
}
