export const namespaced = true;

export const state = () => {
  return {
    loading: false,
  };
};

export const mutations = {
  SET_LOADING(state, value) {
    state.loading = value;
  },
};

export const actions = {
  finish({ commit }) {
    if (process.client) {
      commit('SET_LOADING', false);
    }
  },

  start({ commit }) {
    if (process.client) {
      commit('SET_LOADING', true);
    }
  },
};
