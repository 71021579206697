import { useCookieUniversal } from '~/composables/use-cookie-universal';
import { useStore } from '~/composables/use-store';
import { useRequestUserAgent } from '~/composables/use-request-user-agent';
import { cookieNames } from '~/utils/cookies';
import { actionTypes, authMethods } from '~/utils/analytics';

/**
 * This plugin injects the googleAuth object into the context. This object adds the Google Auth functionality to the app
 * by loading the Google Auth script and initializing the Google Auth object.
 */
export default defineNuxtPlugin({
  parallel: true,
  setup() {
    let loaded = false;
    let initialized = false;

    addScript();

    /**
     * Add the Google Auth script to the page and initialize the Google Auth object when the script is loaded.
     */
    function addScript() {
      try {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        window.onGoogleLibraryLoad = init;
        loaded = true;
        document.head.appendChild(script);
      } catch (e) {
        logErrorToSentry('addScript', e);
      }
    }

    /**
     * Initialize the Google Auth object using the client ID and show the Google One Tap dialog, as well as rendering the
     * Google Sign In button.
     */
    function init() {
      const googleAuthClientId = process.env.googleAuthClientId;

      window.google.accounts?.id.initialize({
        client_id: googleAuthClientId,
        autoSelect: true,
        callback: onResponse,
        ux_mode: 'popup',
        cancel_on_tap_outside: false,
        itp_support: true,
        use_fedcm_for_prompt: true,
      });

      initialized = true;

      showOneTap();
      showSigninButton();
    }

    /**
     * Show the Google One Tap dialog and track the event in Google Analytics.
     */
    function showOneTap() {
      const store = useStore();
      try {
        // Putting this delay to make sure we are not loading the one-tap before we get user data
        setTimeout(() => {
          const isLoggedIn = store.getters['user/isLoggedIn'];
          const isMaintenance = process.env.isMaintenance;

          if (isLoggedIn || isMaintenance || !loaded || !initialized) {
            return;
          }

          if (loaded) {
            // Display the one tap dialog
            window.google.accounts?.id.prompt();
          }
        }, process.env.googleOneTapDelayMilliSec)
      } catch (e) {
        logErrorToSentry('showOneTap', e);
      }
    }

    /**
     * Close the Google One Tap dialog.
     */
    function closeOneTap() {
      try {
        if (loaded && initialized) {
          window.google.accounts?.id.cancel();
        }
      } catch (e) {
        logErrorToSentry('closeOneTap', e);
      }
    }

    /**
     * Renders the Google Sign In button on the div with id 'google-login' and tracks the event in Google Analytics.
     */
    function showSigninButton() {
      const nuxtApp = useNuxtApp();

      try {
        document.querySelectorAll('#google-login').forEach((el) => {
          const ctaId = el.getAttribute('ga-cta-id');

          if (loaded && initialized) {
            window.google.accounts?.id.renderButton(el, {
              type: 'standard',
              theme: 'filled_blue',
              size: 'large',
              text: 'continue_with',
              shape: 'rectangular',
              logo_alignment: 'left',
              width: 210,
              click_listener: () => {
                nuxtApp.$bexGtag.sendCtaEvent({
                  id: ctaId,
                  actionType: actionTypes.clicked,
                });
              },
            });
          }
        });
      } catch (e) {
        logErrorToSentry('showSigninButton', e);
      }
    }

    /**
     * Callback function that's called when the user successfully logs in using Google One Tap or the Google Sign In button.
     */
    function onResponse(response) {
      const token = response.credential;
      const isButton = response.select_by.startsWith('btn');
      const authMethod = isButton ? authMethods.googleButton : authMethods.googleOneTap;
      authenticate(token, authMethod);
    }

    /**
     * Disable one-tap auto select to avoid putting the user in a loop
     */
    function signOut() {
      window.google.accounts?.id.disableAutoSelect();
    }

    /**
     * Authenticates using the "google" strategy, registering the user if
     * necessary.
     *
     * @param {string} token the token that's return by Google after successful login
     * @param {string} authMethod
     */
    async function authenticate(token, authMethod) {
      const nuxtApp = useNuxtApp();
      const cookies = useCookieUniversal();
      const userAgent = useRequestUserAgent();
      const store = useStore();

      // represents the user3 conversion page
      const referSource = cookies.get(cookieNames.referSource);
      const conversionPage = referSource || window.location.href;

      store.dispatch('loading/start');

      try {
        await store.dispatch('user/authenticate', {
          strategy: 'google',
          token,
          oneTap: authMethod === authMethods.googleOneTap,
          conversionPage,
          platform: 'web',
          userAgent,
        });

        // send a CTA event that auth was successful
        nuxtApp.$bexGtag.sendCtaEvent({
          id: authMethod,
          actionType: actionTypes.success,
        });

        // send a recommended GA event that auth was successful
        nuxtApp.$bexGtag.sendLoginEvent({
          method: authMethod,
        });

        store.dispatch('loading/finish');

        cookies.remove(cookieNames.googleAdwordGclid);
      } catch (error) {
        logErrorToSentry('authenticate', error);
      } finally {
        store.dispatch('loading/finish');
        store.dispatch('modal/close');
      }
    }

    /**
     * Takes the function name and the error object and logs it to Sentry
     */
    function logErrorToSentry(functionName, error) {
      if (process.env.logDebug) {
        console.error(`GoogleAuth::${functionName}`, error);
      } else {
        const errorToLog = new GoogleAuthError(`GoogleAuth::${functionName}`, error);
        useNuxtApp().$sentry?.captureException(errorToLog);
      }
    }

    return {
      provide: {
        googleAuth: {
          signOut,
          showSigninButton,
          showOneTap,
          closeOneTap,
        },
      },
    };
  }
});

class GoogleAuthError extends Error {
  constructor(message, error) {
    super(`${message}: ${error.message}`);
    this.name = 'GoogleAuthError';
    this.cause = error;
  }
}
