import isEqual from 'lodash-es/isEqual';
import { nextTick } from 'vue';
import VueGtag, { pageview } from 'vue-gtag';
import { isCommunityListPage, isCommunityMapPage, isCommunityRentalPage } from '~/utils/route-utils';

import {
  getPageTrackerTemplate,
  sendAdEvent,
  sendCtaEvent,
  sendLoginEvent,
  sendSearchEvent,
  sendSignUpEvent,
 } from '~/utils/bex-gtag-wrapper';

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    const router = useRouter();
    const route = useRoute();

    nuxtApp.vueApp.use(VueGtag, {
      config: {
        id: process.env.googleTrackingCode,
      },
      // Defers loading of GA javascript
      deferScriptLoad: true,
      // Customize what params we send on page view events
      pageTrackerTemplate: getPageTrackerTemplate,
      // DO NOT SET THIS TO FALSE: it will cause double page views in Vue 3
      pageTrackerSkipSamePath: true,
    }, router);

    router.afterEach((to, from) => {
      // We want to track page views for query string changes ONLY in community pages.
      if (to.fullPath === from.fullPath) {
        // do nothing if this is the initial page load
        return;
      }

      if (!isCommunityListPage(to) && !isCommunityMapPage(to) && !isCommunityRentalPage(to)) {
        // do nothing if this is not a community page
        return;
      }

      if (to.name === from.name && isEqual(to.params, from.params) && to.path !== from.path) {
        // If the each route is technically the same, but the path differs
        // (e.g. one has a trailing slash and the other doesn't), `vue-gtag`
        // will handle it.
        return;
      }

      if ( ((isCommunityListPage(from) || isCommunityRentalPage(from)) && isCommunityMapPage(to))
        || (isCommunityMapPage(from) && (isCommunityListPage(to) || isCommunityRentalPage(to)))
      ) {
        // we are navigating from a community page to a map page, or vice versa
        // vue-gtag will correctly log a page view for us.
        return;
      }

      nextTick(() => {
        pageview(getPageTrackerTemplate(to));
      });
    });

    return {
      provide: {
        bexGtag: {
          sendAdEvent: (...args) => sendAdEvent(route, ...args),
          sendCtaEvent: (...args) => sendCtaEvent(route, ...args),
          sendLoginEvent: (...args) => sendLoginEvent(...args),
          sendSearchEvent: (...args) => sendSearchEvent(...args),
          sendSignUpEvent: (...args) => sendSignUpEvent(...args),
        },
      },
    };
  },
});
