/**
 * Gets the value of the `x-bot-detect` header.
 * This header is generated in varnish, and is our best determination if the userAgent is a bot.
 *
 * The header helps bexrealty/bex-api determine whether it should use the `seo-dynamic-render`
 * module to render a crawler friendly page
 * @param {Object} headers the `headers` object from a Node.js request
 */
export function getBotDetect(headers) {
  try {
    return headers?.['x-bot-detect'];
  } catch {
    return '';
  }
}

/**
 * Returns the originating IP address of a client by reading the
 * `X-Forwarded-For` header from the given request `headers` object.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-For
 *
 * @param {Object} headers the `headers` object from a Node.js request
 * @return {string} the IP address of the originating client
 */
export function getClientIp(headers) {
  try {
    return headers?.['x-forwarded-for']?.split(',')?.[0].trim() ?? '';
  } catch {
    return '';
  }
}

/**
 * Get request metadata useful for generating pages for a specific user agent or type of request
 * @param {Object} headers the `headers` object from a Node.js request
 */
export function getRequestMetadata(headers) {
  const botDetect = getBotDetect(headers);
  const clientIp = getClientIp(headers);
  const userAgent = getUserAgent(headers);

  return {
    botDetect,
    clientIp,
    userAgent,
  }
}

/**
 * Gets the user agent for the request. Prefer x-forwarded-user-agent over user-agent headers.
 * Cloudfront alters user-agent when user-agent caching is turned off. x-forwarded-user-agent is
 * added to our headers via our edge lambdas to preserve the actual user agent.
 * @param {Object} headers the `headers` object from a Node.js request
 */
export function getUserAgent(headers) {
  try {
    return headers?.['x-forwarded-user-agent'] ?? headers?.['user-agent'];
  } catch {
    return '';
  }
}
