import { createStore, createLogger } from 'vuex';
import * as communityFiltersModule from '~/store/community-filters';
import * as cordlessAdsModule from '~/store/cordless-ads';
import * as indexModule from '~/store/index';
import * as leadProgramModule from '~/store/lead-programs';
import * as loadingModule from '~/store/loading';
import * as modalModule from '~/store/modal';
import * as propertyModule from '~/store/property';
import * as scrollingModule from '~/store/scrolling';
import * as searchModule from '~/store/search';
import * as userModule from '~/store/user';

export default defineNuxtPlugin({
  parallel: true,
  async setup(nuxtApp) {
    const store = createStore({
      modules: {
        'community-filters': communityFiltersModule,
        'cordless-ads': cordlessAdsModule,
        index: indexModule,
        'lead-programs': leadProgramModule,
        loading: loadingModule,
        modal: modalModule,
        property: propertyModule,
        scrolling: scrollingModule,
        search: searchModule,
        user: userModule,
      },
      plugins: import.meta.dev && import.meta.client ? [createLogger()] : [],
    });

    nuxtApp.vueApp.use(store);

    if (import.meta.server) {
      await store.dispatch('nuxtServerInit');
      nuxtApp.payload.vuex = store.state;
    } else if (nuxtApp.payload.vuex) {
      store.replaceState(nuxtApp.payload.vuex);
    }

    return {
      provide: {
        store,
      },
    };
  },
});
