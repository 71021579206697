import { apiErrorTypes } from '~/errors/api-errors';
import { RequestTimer } from '~/services/models/request-timer';
import { AsyncDataTimer } from '~/utils/async-data-timer';
import logger from '~/services/logger/service-logger';

import BaseService from './base-service';

/** The base of every feathers service */
class FeathersService extends BaseService {
  #service;

  constructor(service) {
    super(service.path);
    this.#service = service;
  }

  get service() {
    return this.#service;
  }

  /**
   * Gets the object that should be appened to every service call
   * @param {String} serviceFuncName
   * @param {RequestTimer|Object} requestMetric
   * @returns
   */
  #getBaseQuery(serviceFuncName, requestMetric) {
    try {
      if (requestMetric instanceof RequestTimer || requestMetric instanceof AsyncDataTimer) {
        return requestMetric.getServiceBaseQuery(serviceFuncName);
      } else if (!requestMetric) {
        const requestTimer = new RequestTimer('default');
        return requestTimer.getServiceBaseQuery(serviceFuncName);
      } else {
        return requestMetric;
      }
    } catch (e) {
      // don't throw error while getting something only used for logging
      return {};
    }
  }

  /**
   * Determines if an error should be logged. Do not log the following:
   *
   *  - BexrealtyHttpError, subType = RedirectError: redirects occurr, don't log to prevent cluttering kibana
   * @param {Error} e
   */
  #isErrorLoggable(e) {
    try {
      if (e.className === apiErrorTypes.RedirectError) {
        return false;
      }

      return true;
    } catch (e) {
      return true;
    }
  }

  /**
   * A service request
   * @param {Function} serviceFunc function used for calling services
   * @param {String} serviceFuncName a label for the name of the function being run
   * @param {RequestTimer|Object} requestMetric either a RequestTimer instance or an object that contains $requestId and $timeLabel
   * @param {*} [defaultReturn=null] what gets returned if the serviceFunc throws an error
   * @param {Object} [$sentry=null] sentry instance
   * @returns result of serviceFunc
   */
  async request(serviceFunc, serviceFuncName, requestMetric, defaultReturn = null, $sentry = null) {
    const baseQuery = this.#getBaseQuery(serviceFuncName, requestMetric);
    const { $requestId: requestId, $timeLabel: timeLabel } = baseQuery;

    try {
      logger.logFeathersServiceStart(requestId, timeLabel);
      return await serviceFunc(baseQuery);
    } catch (e) {
      if (this.#isErrorLoggable(e)) {
        logger.logFeathersError(requestId, timeLabel, e, $sentry);
      }

      return defaultReturn;
    } finally {
      logger.logFeathersServiceEnd(requestId, timeLabel);
    }
  }

  /**
   * A service request that throws errors if serviceFunc errors
   * @param {Function} serviceFunc function used for calling services
   * @param {String} serviceFuncName a label for the name of the function being run
   * @param {RequestTimer|Object} requestMetric either a RequestTimer instance or an object that contains $requestId and $timeLabel
   * @param {Object} [$sentry=null] sentry instance
   * @returns result of serviceFunc
   */
  async throwableRequest(serviceFunc, serviceFuncName, requestMetric, $sentry = null) {
    const baseQuery = this.#getBaseQuery(serviceFuncName, requestMetric);
    const { $requestId: requestId, $timeLabel: timeLabel } = baseQuery;

    try {
      logger.logFeathersServiceStart(requestId, timeLabel);
      return await serviceFunc(baseQuery);
    } catch (e) {
      if (this.#isErrorLoggable(e)) {
        logger.logFeathersError(requestId, timeLabel, e, $sentry);
      }

      throw e;
    } finally {
      logger.logFeathersServiceEnd(requestId, timeLabel);
    }
  }

  /**
   *
   * @param {Function} handler
   * @param {string} methodName
   * @param {RequestTimer} timer
   */
  async getAsyncData(handler, methodName, timer) {
    const baseQuery = this.#getBaseQuery(methodName, timer);

    try {
      timer.start();
      const data = await handler(baseQuery);
      return data;
    } catch (err) {
      if (process.server && this.#isErrorLoggable(err)) {
        console.error(`${timer}: [ERROR]: ${JSON.stringify(err, Object.getOwnPropertyNames(err))}`);
      }
      throw err;
    } finally {
      timer.stop();
    }
  }
}

export default FeathersService;
