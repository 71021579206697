import FeathersService from '~/services/base/feathers-service';

export class LegacyLoginLocationService extends FeathersService {
  /**
   * creates a login location
   * @param {String} username
   * @param {String} ip
   * @param {RequestTimer} requestTimer
   * @param {Object} $sentry sentry instance
   */
  async createLoginLocation(username, ip, requestTimer, $sentry) {
    return await super.request(async (baseQuery) => {
      await this.service.create({
        ip,
        username,
      }, {
        query: {
          ...baseQuery,
        },
      });
    }, 'createLoginLocation', requestTimer, null, $sentry);
  }
}
