// User state
export const state = () => ({
  properties: [],
  pagedProperties: [],
  pageLimit: 46,
  currentPageNumber: 1,
});

export const getters = {
  properties (state) {
    const properties = state.properties || [];
    return properties;
  },
  /**
   * Reduces the number of total properties to a single page. This allows the map to display up
   * to 500 properties, and only display 46 listing cards. If we were to display 500 properties
   * on the map and the list, the browser will become very slow
   */
  pagedProperties (state) {
    const pageLimit = state.pageLimit;
    const properties = state.properties;

    const start = 0;
    const end = pageLimit;

    const result = properties.slice(start, end);
    return result;
  },

  hasPagination(state) {
    return state.properties.length > state.pageLimit;
  },
  pageLimit(state) {
    return state.pageLimit;
  },
  pageTotal(state) {
    return state.pageTotal;
  },
  currentPageNumber(state) {
    return state.currentPageNumber || 1;
  },
  nextPageNumber(state) {
    return state.currentPageNumber + 1;
  },
  previousPageNumber(state) {
    if (state.currentPageNumber < 2) {
      return state.currentPageNumber;
    } else {
      return state.currentPageNumber - 1;
    }
  }
}

// Mutations
// Updates the state, only synchronous methods here
export const mutations = {
  setProperties (state, properties) {
    state.properties = properties;
  },
  setPageLimit (state, limit) {
    state.pageLimit = limit;
  },
  setPageNumber (state, pageNumber) {
    state.currentPageNumber = Number(pageNumber);
  },
  setPageTotal(state, pageTotal) {
    state.pageTotal = pageTotal;
  }
}

export const namespaced = true;
