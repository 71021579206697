import { default as single_45family_45home3UkdMCwptzMeta } from "/home/circleci/project/pages/[state]/[city]/[address]/single-family-home.vue?macro=true";
import { default as Comps_46phph1GsSUFjGxMeta } from "/home/circleci/project/pages/[state]/[city]/Comps.php.vue?macro=true";
import { default as indexvfSyFwcBYcMeta } from "/home/circleci/project/pages/[state]/[city]/index.vue?macro=true";
import { default as map2Rz54RyGpOMeta } from "/home/circleci/project/pages/[state]/[city]/map.vue?macro=true";
import { default as indexPCNhp1GO6PMeta } from "/home/circleci/project/pages/[state]/index.vue?macro=true";
import { default as email_45preferencesQiZntlDmU0Meta } from "/home/circleci/project/pages/account/email-preferences.vue?macro=true";
import { default as favorite_45listings1Pme90SY9TMeta } from "/home/circleci/project/pages/account/favorite-listings.vue?macro=true";
import { default as index2O1LHDN0wSMeta } from "/home/circleci/project/pages/account/index.vue?macro=true";
import { default as _91token_93EdUpfH4vC4Meta } from "/home/circleci/project/pages/account/password-reset/[token].vue?macro=true";
import { default as recently_45viewedUQ7Ctia0dBMeta } from "/home/circleci/project/pages/account/recently-viewed.vue?macro=true";
import { default as saved_45searchesXuYyMgYY3bMeta } from "/home/circleci/project/pages/account/saved-searches.vue?macro=true";
import { default as _91email_939lLQ5j8x4aMeta } from "/home/circleci/project/pages/agents/[email].vue?macro=true";
import { default as index4ghiNzqqNwMeta } from "/home/circleci/project/pages/agents/index.vue?macro=true";
import { default as join_45usK08CxIt9JOMeta } from "/home/circleci/project/pages/agents/join-us.vue?macro=true";
import { default as indexcmjfRCyujuMeta } from "/home/circleci/project/pages/agents/lead-programs/index.vue?macro=true";
import { default as indexVjYuzNZSmsMeta } from "/home/circleci/project/pages/agents/lead-programs/interests/index.vue?macro=true";
import { default as app00X7wzctepMeta } from "/home/circleci/project/pages/app.vue?macro=true";
import { default as contact_46phph0qrcdYglmMeta } from "/home/circleci/project/pages/contact.php.vue?macro=true";
import { default as indexbVK8ERtDnaMeta } from "/home/circleci/project/pages/index.vue?macro=true";
import { default as leads_45isakUqfgecxieMeta } from "/home/circleci/project/pages/leads-isa.vue?macro=true";
import { default as maintenanceT62tl0E35SMeta } from "/home/circleci/project/pages/maintenance.vue?macro=true";
import { default as _91token_93BNGcxEU6XeMeta } from "/home/circleci/project/pages/my-bex/survey/[token].vue?macro=true";
import { default as ccpaaCd1LXibfdMeta } from "/home/circleci/project/pages/notices/ccpa.vue?macro=true";
import { default as dmcaLiqAn1S4r7Meta } from "/home/circleci/project/pages/notices/dmca.vue?macro=true";
import { default as eulaM03mhh9vxDMeta } from "/home/circleci/project/pages/notices/eula.vue?macro=true";
import { default as digital_45advertisingLgGBQvScBIMeta } from "/home/circleci/project/pages/partners/digital-advertising.vue?macro=true";
import { default as privacy_46phpi7BOIqtqGkMeta } from "/home/circleci/project/pages/privacy.php.vue?macro=true";
import { default as calculatorstpzaZh385uMeta } from "/home/circleci/project/pages/resources/calculators.vue?macro=true";
import { default as equity_45loanskOeX5cWY39Meta } from "/home/circleci/project/pages/resources/equity-loans.vue?macro=true";
import { default as get_45pre_45approvedG2pdAinEPVMeta } from "/home/circleci/project/pages/resources/get-pre-approved.vue?macro=true";
import { default as indexFRAU6mkEfTMeta } from "/home/circleci/project/pages/resources/index.vue?macro=true";
import { default as insurance_45rates9DKvQJMc8jMeta } from "/home/circleci/project/pages/resources/insurance-rates.vue?macro=true";
import { default as internet_45ratescCD4yaJpkxMeta } from "/home/circleci/project/pages/resources/internet-rates.vue?macro=true";
import { default as mortgage_45rates78WYbDM7JVMeta } from "/home/circleci/project/pages/resources/mortgage-rates.vue?macro=true";
import { default as safelist_45emailsBdqOxQrTyOMeta } from "/home/circleci/project/pages/resources/safelist-emails.vue?macro=true";
import { default as va_45loansdEKHbGm0BIMeta } from "/home/circleci/project/pages/resources/va-loans.vue?macro=true";
import { default as selloMAHYUBAL1Meta } from "/home/circleci/project/pages/sell.vue?macro=true";
import { default as new_45listingsoeT3etJyQBMeta } from "/home/circleci/project/pages/sitemaps/[state]/new-listings.vue?macro=true";
import { default as zipcodeaUR9mC1BupMeta } from "/home/circleci/project/pages/sitemaps/[state]/zipcode.vue?macro=true";
import { default as indexM13169J2ogMeta } from "/home/circleci/project/pages/sitemaps/index.vue?macro=true";
import { default as terms_46phpa7f25qaHPCMeta } from "/home/circleci/project/pages/terms.php.vue?macro=true";
import { default as indexcE7WhV9lrlMeta } from "/home/circleci/project/pages/zipcode/[zip]/index.vue?macro=true";
import { default as indexuSZCcSSFkZMeta } from "/home/circleci/project/pages/zipcode/index.vue?macro=true";
import { default as index2nPoivyZylMeta } from "~/pages/sitemaps/index.vue?macro=true";
import { default as new_45listingsZNllIDX0oGMeta } from "~/pages/sitemaps/[state]/new-listings.vue?macro=true";
import { default as zipcodemVEVoTnvVjMeta } from "~/pages/sitemaps/[state]/zipcode.vue?macro=true";
import { default as indexrOHWDadTRNMeta } from "~/pages/[state]/index.vue?macro=true";
import { default as indexfuQwzuRM4OMeta } from "~/pages/[state]/[city]/index.vue?macro=true";
import { default as mappBG2WZxxLKMeta } from "~/pages/[state]/[city]/map.vue?macro=true";
import { default as single_45family_45home0OHAd5fr4pMeta } from "~/pages/[state]/[city]/[address]/single-family-home.vue?macro=true";
import { default as Comps_46phpT7XfwaIOLFMeta } from "~/pages/[state]/[city]/Comps.php.vue?macro=true";
import { default as indexOQSNWYveVLMeta } from "~/pages/zipcode/index.vue?macro=true";
import { default as indext9NkWxHFcSMeta } from "~/pages/zipcode/[zip]/index.vue?macro=true";
export default [
  {
    name: single_45family_45home3UkdMCwptzMeta?.name ?? "state-city-address-single-family-home",
    path: single_45family_45home3UkdMCwptzMeta?.path ?? "/:state()/:city()/:address()/single-family-home",
    meta: single_45family_45home3UkdMCwptzMeta || {},
    alias: single_45family_45home3UkdMCwptzMeta?.alias || [],
    redirect: single_45family_45home3UkdMCwptzMeta?.redirect,
    component: () => import("/home/circleci/project/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: Comps_46phph1GsSUFjGxMeta?.name ?? "state-city-Comps.php",
    path: Comps_46phph1GsSUFjGxMeta?.path ?? "/:state()/:city()/Comps.php",
    meta: Comps_46phph1GsSUFjGxMeta || {},
    alias: Comps_46phph1GsSUFjGxMeta?.alias || [],
    redirect: Comps_46phph1GsSUFjGxMeta?.redirect,
    component: () => import("/home/circleci/project/pages/[state]/[city]/Comps.php.vue").then(m => m.default || m)
  },
  {
    name: indexvfSyFwcBYcMeta?.name ?? "state-city",
    path: indexvfSyFwcBYcMeta?.path ?? "/:state()/:city()",
    meta: indexvfSyFwcBYcMeta || {},
    alias: indexvfSyFwcBYcMeta?.alias || [],
    redirect: indexvfSyFwcBYcMeta?.redirect,
    component: () => import("/home/circleci/project/pages/[state]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: map2Rz54RyGpOMeta?.name ?? "state-city-map",
    path: map2Rz54RyGpOMeta?.path ?? "/:state()/:city()/map",
    meta: map2Rz54RyGpOMeta || {},
    alias: map2Rz54RyGpOMeta?.alias || [],
    redirect: map2Rz54RyGpOMeta?.redirect,
    component: () => import("/home/circleci/project/pages/[state]/[city]/map.vue").then(m => m.default || m)
  },
  {
    name: indexPCNhp1GO6PMeta?.name ?? "state",
    path: indexPCNhp1GO6PMeta?.path ?? "/:state()",
    meta: indexPCNhp1GO6PMeta || {},
    alias: indexPCNhp1GO6PMeta?.alias || [],
    redirect: indexPCNhp1GO6PMeta?.redirect,
    component: () => import("/home/circleci/project/pages/[state]/index.vue").then(m => m.default || m)
  },
  {
    name: email_45preferencesQiZntlDmU0Meta?.name ?? "account-email-preferences",
    path: email_45preferencesQiZntlDmU0Meta?.path ?? "/account/email-preferences",
    meta: email_45preferencesQiZntlDmU0Meta || {},
    alias: email_45preferencesQiZntlDmU0Meta?.alias || [],
    redirect: email_45preferencesQiZntlDmU0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/account/email-preferences.vue").then(m => m.default || m)
  },
  {
    name: favorite_45listings1Pme90SY9TMeta?.name ?? "account-favorite-listings",
    path: favorite_45listings1Pme90SY9TMeta?.path ?? "/account/favorite-listings",
    meta: favorite_45listings1Pme90SY9TMeta || {},
    alias: favorite_45listings1Pme90SY9TMeta?.alias || [],
    redirect: favorite_45listings1Pme90SY9TMeta?.redirect,
    component: () => import("/home/circleci/project/pages/account/favorite-listings.vue").then(m => m.default || m)
  },
  {
    name: index2O1LHDN0wSMeta?.name ?? "account",
    path: index2O1LHDN0wSMeta?.path ?? "/account",
    meta: index2O1LHDN0wSMeta || {},
    alias: index2O1LHDN0wSMeta?.alias || [],
    redirect: index2O1LHDN0wSMeta?.redirect,
    component: () => import("/home/circleci/project/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93EdUpfH4vC4Meta?.name ?? "account-password-reset-token",
    path: _91token_93EdUpfH4vC4Meta?.path ?? "/account/password-reset/:token()",
    meta: _91token_93EdUpfH4vC4Meta || {},
    alias: _91token_93EdUpfH4vC4Meta?.alias || [],
    redirect: _91token_93EdUpfH4vC4Meta?.redirect,
    component: () => import("/home/circleci/project/pages/account/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: recently_45viewedUQ7Ctia0dBMeta?.name ?? "account-recently-viewed",
    path: recently_45viewedUQ7Ctia0dBMeta?.path ?? "/account/recently-viewed",
    meta: recently_45viewedUQ7Ctia0dBMeta || {},
    alias: recently_45viewedUQ7Ctia0dBMeta?.alias || [],
    redirect: recently_45viewedUQ7Ctia0dBMeta?.redirect,
    component: () => import("/home/circleci/project/pages/account/recently-viewed.vue").then(m => m.default || m)
  },
  {
    name: saved_45searchesXuYyMgYY3bMeta?.name ?? "account-saved-searches",
    path: saved_45searchesXuYyMgYY3bMeta?.path ?? "/account/saved-searches",
    meta: saved_45searchesXuYyMgYY3bMeta || {},
    alias: saved_45searchesXuYyMgYY3bMeta?.alias || [],
    redirect: saved_45searchesXuYyMgYY3bMeta?.redirect,
    component: () => import("/home/circleci/project/pages/account/saved-searches.vue").then(m => m.default || m)
  },
  {
    name: _91email_939lLQ5j8x4aMeta?.name ?? "agents-email",
    path: _91email_939lLQ5j8x4aMeta?.path ?? "/agents/:email()",
    meta: _91email_939lLQ5j8x4aMeta || {},
    alias: _91email_939lLQ5j8x4aMeta?.alias || [],
    redirect: _91email_939lLQ5j8x4aMeta?.redirect,
    component: () => import("/home/circleci/project/pages/agents/[email].vue").then(m => m.default || m)
  },
  {
    name: index4ghiNzqqNwMeta?.name ?? "agents",
    path: index4ghiNzqqNwMeta?.path ?? "/agents",
    meta: index4ghiNzqqNwMeta || {},
    alias: index4ghiNzqqNwMeta?.alias || [],
    redirect: index4ghiNzqqNwMeta?.redirect,
    component: () => import("/home/circleci/project/pages/agents/index.vue").then(m => m.default || m)
  },
  {
    name: join_45usK08CxIt9JOMeta?.name ?? "agents-join-us",
    path: join_45usK08CxIt9JOMeta?.path ?? "/agents/join-us",
    meta: join_45usK08CxIt9JOMeta || {},
    alias: join_45usK08CxIt9JOMeta?.alias || [],
    redirect: join_45usK08CxIt9JOMeta?.redirect,
    component: () => import("/home/circleci/project/pages/agents/join-us.vue").then(m => m.default || m)
  },
  {
    name: indexcmjfRCyujuMeta?.name ?? "agents-lead-programs",
    path: indexcmjfRCyujuMeta?.path ?? "/agents/lead-programs",
    meta: indexcmjfRCyujuMeta || {},
    alias: indexcmjfRCyujuMeta?.alias || [],
    redirect: indexcmjfRCyujuMeta?.redirect,
    component: () => import("/home/circleci/project/pages/agents/lead-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexVjYuzNZSmsMeta?.name ?? "agents-lead-programs-interests",
    path: indexVjYuzNZSmsMeta?.path ?? "/agents/lead-programs/interests",
    meta: indexVjYuzNZSmsMeta || {},
    alias: indexVjYuzNZSmsMeta?.alias || [],
    redirect: indexVjYuzNZSmsMeta?.redirect,
    component: () => import("/home/circleci/project/pages/agents/lead-programs/interests/index.vue").then(m => m.default || m)
  },
  {
    name: app00X7wzctepMeta?.name ?? "app",
    path: app00X7wzctepMeta?.path ?? "/app",
    meta: app00X7wzctepMeta || {},
    alias: app00X7wzctepMeta?.alias || [],
    redirect: app00X7wzctepMeta?.redirect,
    component: () => import("/home/circleci/project/pages/app.vue").then(m => m.default || m)
  },
  {
    name: contact_46phph0qrcdYglmMeta?.name ?? "contact.php",
    path: contact_46phph0qrcdYglmMeta?.path ?? "/contact.php",
    meta: contact_46phph0qrcdYglmMeta || {},
    alias: contact_46phph0qrcdYglmMeta?.alias || [],
    redirect: contact_46phph0qrcdYglmMeta?.redirect,
    component: () => import("/home/circleci/project/pages/contact.php.vue").then(m => m.default || m)
  },
  {
    name: indexbVK8ERtDnaMeta?.name ?? "index",
    path: indexbVK8ERtDnaMeta?.path ?? "/",
    meta: indexbVK8ERtDnaMeta || {},
    alias: indexbVK8ERtDnaMeta?.alias || [],
    redirect: indexbVK8ERtDnaMeta?.redirect,
    component: () => import("/home/circleci/project/pages/index.vue").then(m => m.default || m)
  },
  {
    name: leads_45isakUqfgecxieMeta?.name ?? "leads-isa",
    path: leads_45isakUqfgecxieMeta?.path ?? "/leads-isa",
    meta: leads_45isakUqfgecxieMeta || {},
    alias: leads_45isakUqfgecxieMeta?.alias || [],
    redirect: leads_45isakUqfgecxieMeta?.redirect,
    component: () => import("/home/circleci/project/pages/leads-isa.vue").then(m => m.default || m)
  },
  {
    name: maintenanceT62tl0E35SMeta?.name ?? "maintenance",
    path: maintenanceT62tl0E35SMeta?.path ?? "/maintenance",
    meta: maintenanceT62tl0E35SMeta || {},
    alias: maintenanceT62tl0E35SMeta?.alias || [],
    redirect: maintenanceT62tl0E35SMeta?.redirect,
    component: () => import("/home/circleci/project/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91token_93BNGcxEU6XeMeta?.name ?? "my-bex-survey-token",
    path: _91token_93BNGcxEU6XeMeta?.path ?? "/my-bex/survey/:token()",
    meta: _91token_93BNGcxEU6XeMeta || {},
    alias: _91token_93BNGcxEU6XeMeta?.alias || [],
    redirect: _91token_93BNGcxEU6XeMeta?.redirect,
    component: () => import("/home/circleci/project/pages/my-bex/survey/[token].vue").then(m => m.default || m)
  },
  {
    name: ccpaaCd1LXibfdMeta?.name ?? "notices-ccpa",
    path: ccpaaCd1LXibfdMeta?.path ?? "/notices/ccpa",
    meta: ccpaaCd1LXibfdMeta || {},
    alias: ccpaaCd1LXibfdMeta?.alias || [],
    redirect: ccpaaCd1LXibfdMeta?.redirect,
    component: () => import("/home/circleci/project/pages/notices/ccpa.vue").then(m => m.default || m)
  },
  {
    name: dmcaLiqAn1S4r7Meta?.name ?? "notices-dmca",
    path: dmcaLiqAn1S4r7Meta?.path ?? "/notices/dmca",
    meta: dmcaLiqAn1S4r7Meta || {},
    alias: dmcaLiqAn1S4r7Meta?.alias || [],
    redirect: dmcaLiqAn1S4r7Meta?.redirect,
    component: () => import("/home/circleci/project/pages/notices/dmca.vue").then(m => m.default || m)
  },
  {
    name: eulaM03mhh9vxDMeta?.name ?? "notices-eula",
    path: eulaM03mhh9vxDMeta?.path ?? "/notices/eula",
    meta: eulaM03mhh9vxDMeta || {},
    alias: eulaM03mhh9vxDMeta?.alias || [],
    redirect: eulaM03mhh9vxDMeta?.redirect,
    component: () => import("/home/circleci/project/pages/notices/eula.vue").then(m => m.default || m)
  },
  {
    name: digital_45advertisingLgGBQvScBIMeta?.name ?? "partners-digital-advertising",
    path: digital_45advertisingLgGBQvScBIMeta?.path ?? "/partners/digital-advertising",
    meta: digital_45advertisingLgGBQvScBIMeta || {},
    alias: digital_45advertisingLgGBQvScBIMeta?.alias || [],
    redirect: digital_45advertisingLgGBQvScBIMeta?.redirect,
    component: () => import("/home/circleci/project/pages/partners/digital-advertising.vue").then(m => m.default || m)
  },
  {
    name: privacy_46phpi7BOIqtqGkMeta?.name ?? "privacy.php",
    path: privacy_46phpi7BOIqtqGkMeta?.path ?? "/privacy.php",
    meta: privacy_46phpi7BOIqtqGkMeta || {},
    alias: privacy_46phpi7BOIqtqGkMeta?.alias || [],
    redirect: privacy_46phpi7BOIqtqGkMeta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy.php.vue").then(m => m.default || m)
  },
  {
    name: calculatorstpzaZh385uMeta?.name ?? "resources-calculators",
    path: calculatorstpzaZh385uMeta?.path ?? "/resources/calculators",
    meta: calculatorstpzaZh385uMeta || {},
    alias: calculatorstpzaZh385uMeta?.alias || [],
    redirect: calculatorstpzaZh385uMeta?.redirect,
    component: () => import("/home/circleci/project/pages/resources/calculators.vue").then(m => m.default || m)
  },
  {
    name: equity_45loanskOeX5cWY39Meta?.name ?? "resources-equity-loans",
    path: equity_45loanskOeX5cWY39Meta?.path ?? "/resources/equity-loans",
    meta: equity_45loanskOeX5cWY39Meta || {},
    alias: equity_45loanskOeX5cWY39Meta?.alias || [],
    redirect: equity_45loanskOeX5cWY39Meta?.redirect,
    component: () => import("/home/circleci/project/pages/resources/equity-loans.vue").then(m => m.default || m)
  },
  {
    name: get_45pre_45approvedG2pdAinEPVMeta?.name ?? "resources-get-pre-approved",
    path: get_45pre_45approvedG2pdAinEPVMeta?.path ?? "/resources/get-pre-approved",
    meta: get_45pre_45approvedG2pdAinEPVMeta || {},
    alias: get_45pre_45approvedG2pdAinEPVMeta?.alias || [],
    redirect: get_45pre_45approvedG2pdAinEPVMeta?.redirect,
    component: () => import("/home/circleci/project/pages/resources/get-pre-approved.vue").then(m => m.default || m)
  },
  {
    name: indexFRAU6mkEfTMeta?.name ?? "resources",
    path: indexFRAU6mkEfTMeta?.path ?? "/resources",
    meta: indexFRAU6mkEfTMeta || {},
    alias: indexFRAU6mkEfTMeta?.alias || [],
    redirect: indexFRAU6mkEfTMeta?.redirect,
    component: () => import("/home/circleci/project/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: insurance_45rates9DKvQJMc8jMeta?.name ?? "resources-insurance-rates",
    path: insurance_45rates9DKvQJMc8jMeta?.path ?? "/resources/insurance-rates",
    meta: insurance_45rates9DKvQJMc8jMeta || {},
    alias: insurance_45rates9DKvQJMc8jMeta?.alias || [],
    redirect: insurance_45rates9DKvQJMc8jMeta?.redirect,
    component: () => import("/home/circleci/project/pages/resources/insurance-rates.vue").then(m => m.default || m)
  },
  {
    name: internet_45ratescCD4yaJpkxMeta?.name ?? "resources-internet-rates",
    path: internet_45ratescCD4yaJpkxMeta?.path ?? "/resources/internet-rates",
    meta: internet_45ratescCD4yaJpkxMeta || {},
    alias: internet_45ratescCD4yaJpkxMeta?.alias || [],
    redirect: internet_45ratescCD4yaJpkxMeta?.redirect,
    component: () => import("/home/circleci/project/pages/resources/internet-rates.vue").then(m => m.default || m)
  },
  {
    name: mortgage_45rates78WYbDM7JVMeta?.name ?? "resources-mortgage-rates",
    path: mortgage_45rates78WYbDM7JVMeta?.path ?? "/resources/mortgage-rates",
    meta: mortgage_45rates78WYbDM7JVMeta || {},
    alias: mortgage_45rates78WYbDM7JVMeta?.alias || [],
    redirect: mortgage_45rates78WYbDM7JVMeta?.redirect,
    component: () => import("/home/circleci/project/pages/resources/mortgage-rates.vue").then(m => m.default || m)
  },
  {
    name: safelist_45emailsBdqOxQrTyOMeta?.name ?? "resources-safelist-emails",
    path: safelist_45emailsBdqOxQrTyOMeta?.path ?? "/resources/safelist-emails",
    meta: safelist_45emailsBdqOxQrTyOMeta || {},
    alias: safelist_45emailsBdqOxQrTyOMeta?.alias || [],
    redirect: safelist_45emailsBdqOxQrTyOMeta?.redirect,
    component: () => import("/home/circleci/project/pages/resources/safelist-emails.vue").then(m => m.default || m)
  },
  {
    name: va_45loansdEKHbGm0BIMeta?.name ?? "resources-va-loans",
    path: va_45loansdEKHbGm0BIMeta?.path ?? "/resources/va-loans",
    meta: va_45loansdEKHbGm0BIMeta || {},
    alias: va_45loansdEKHbGm0BIMeta?.alias || [],
    redirect: va_45loansdEKHbGm0BIMeta?.redirect,
    component: () => import("/home/circleci/project/pages/resources/va-loans.vue").then(m => m.default || m)
  },
  {
    name: selloMAHYUBAL1Meta?.name ?? "sell",
    path: selloMAHYUBAL1Meta?.path ?? "/sell",
    meta: selloMAHYUBAL1Meta || {},
    alias: selloMAHYUBAL1Meta?.alias || [],
    redirect: selloMAHYUBAL1Meta?.redirect,
    component: () => import("/home/circleci/project/pages/sell.vue").then(m => m.default || m)
  },
  {
    name: new_45listingsoeT3etJyQBMeta?.name ?? "sitemaps-state-new-listings",
    path: new_45listingsoeT3etJyQBMeta?.path ?? "/sitemaps/:state()/new-listings",
    meta: new_45listingsoeT3etJyQBMeta || {},
    alias: new_45listingsoeT3etJyQBMeta?.alias || [],
    redirect: new_45listingsoeT3etJyQBMeta?.redirect,
    component: () => import("/home/circleci/project/pages/sitemaps/[state]/new-listings.vue").then(m => m.default || m)
  },
  {
    name: zipcodeaUR9mC1BupMeta?.name ?? "sitemaps-state-zipcode",
    path: zipcodeaUR9mC1BupMeta?.path ?? "/sitemaps/:state()/zipcode",
    meta: zipcodeaUR9mC1BupMeta || {},
    alias: zipcodeaUR9mC1BupMeta?.alias || [],
    redirect: zipcodeaUR9mC1BupMeta?.redirect,
    component: () => import("/home/circleci/project/pages/sitemaps/[state]/zipcode.vue").then(m => m.default || m)
  },
  {
    name: indexM13169J2ogMeta?.name ?? "sitemaps",
    path: indexM13169J2ogMeta?.path ?? "/sitemaps",
    meta: indexM13169J2ogMeta || {},
    alias: indexM13169J2ogMeta?.alias || [],
    redirect: indexM13169J2ogMeta?.redirect,
    component: () => import("/home/circleci/project/pages/sitemaps/index.vue").then(m => m.default || m)
  },
  {
    name: terms_46phpa7f25qaHPCMeta?.name ?? "terms.php",
    path: terms_46phpa7f25qaHPCMeta?.path ?? "/terms.php",
    meta: terms_46phpa7f25qaHPCMeta || {},
    alias: terms_46phpa7f25qaHPCMeta?.alias || [],
    redirect: terms_46phpa7f25qaHPCMeta?.redirect,
    component: () => import("/home/circleci/project/pages/terms.php.vue").then(m => m.default || m)
  },
  {
    name: indexcE7WhV9lrlMeta?.name ?? "zipcode-zip",
    path: indexcE7WhV9lrlMeta?.path ?? "/zipcode/:zip()",
    meta: indexcE7WhV9lrlMeta || {},
    alias: indexcE7WhV9lrlMeta?.alias || [],
    redirect: indexcE7WhV9lrlMeta?.redirect,
    component: () => import("/home/circleci/project/pages/zipcode/[zip]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuSZCcSSFkZMeta?.name ?? "zipcode",
    path: indexuSZCcSSFkZMeta?.path ?? "/zipcode",
    meta: indexuSZCcSSFkZMeta || {},
    alias: indexuSZCcSSFkZMeta?.alias || [],
    redirect: indexuSZCcSSFkZMeta?.redirect,
    component: () => import("/home/circleci/project/pages/zipcode/index.vue").then(m => m.default || m)
  },
  {
    name: index2nPoivyZylMeta?.name ?? "sitemaps-state",
    path: index2nPoivyZylMeta?.path ?? "/sitemaps/:state",
    meta: index2nPoivyZylMeta || {},
    alias: index2nPoivyZylMeta?.alias || [],
    redirect: index2nPoivyZylMeta?.redirect,
    component: () => import("~/pages/sitemaps/index.vue").then(m => m.default || m)
  },
  {
    name: new_45listingsZNllIDX0oGMeta?.name ?? "sitemaps-state-new-rental-listings",
    path: new_45listingsZNllIDX0oGMeta?.path ?? "/sitemaps/:state/new-rental-listings",
    meta: new_45listingsZNllIDX0oGMeta || {},
    alias: new_45listingsZNllIDX0oGMeta?.alias || [],
    redirect: new_45listingsZNllIDX0oGMeta?.redirect,
    component: () => import("~/pages/sitemaps/[state]/new-listings.vue").then(m => m.default || m)
  },
  {
    name: zipcodemVEVoTnvVjMeta?.name ?? "sitemaps-state-zipcode-rentals",
    path: zipcodemVEVoTnvVjMeta?.path ?? "/sitemaps/:state/zipcode-rentals",
    meta: zipcodemVEVoTnvVjMeta || {},
    alias: zipcodemVEVoTnvVjMeta?.alias || [],
    redirect: zipcodemVEVoTnvVjMeta?.redirect,
    component: () => import("~/pages/sitemaps/[state]/zipcode.vue").then(m => m.default || m)
  },
  {
    name: index2nPoivyZylMeta?.name ?? "sitemaps-city",
    path: index2nPoivyZylMeta?.path ?? "/sitemaps/:state/:city",
    meta: index2nPoivyZylMeta || {},
    alias: index2nPoivyZylMeta?.alias || [],
    redirect: index2nPoivyZylMeta?.redirect,
    component: () => import("~/pages/sitemaps/index.vue").then(m => m.default || m)
  },
  {
    name: index2nPoivyZylMeta?.name ?? "sitemaps-nc",
    path: index2nPoivyZylMeta?.path ?? "/sitemaps/:state/:city/:nc",
    meta: index2nPoivyZylMeta || {},
    alias: index2nPoivyZylMeta?.alias || [],
    redirect: index2nPoivyZylMeta?.redirect,
    component: () => import("~/pages/sitemaps/index.vue").then(m => m.default || m)
  },
  {
    name: indexrOHWDadTRNMeta?.name ?? "state-rentals",
    path: indexrOHWDadTRNMeta?.path ?? "/:state/Rentals.php",
    meta: indexrOHWDadTRNMeta || {},
    alias: indexrOHWDadTRNMeta?.alias || [],
    redirect: indexrOHWDadTRNMeta?.redirect,
    component: () => import("~/pages/[state]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfuQwzuRM4OMeta?.name ?? "state-city-rentals",
    path: indexfuQwzuRM4OMeta?.path ?? "/:state/:city/Rentals.php",
    meta: indexfuQwzuRM4OMeta || {},
    alias: indexfuQwzuRM4OMeta?.alias || [],
    redirect: indexfuQwzuRM4OMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: mappBG2WZxxLKMeta?.name ?? "state-city-map-rentals",
    path: mappBG2WZxxLKMeta?.path ?? "/:state/:city/map/Rentals.php",
    meta: mappBG2WZxxLKMeta || {},
    alias: mappBG2WZxxLKMeta?.alias || [],
    redirect: mappBG2WZxxLKMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/map.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-single-family-home-rental",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/single-family-home/rental",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-townhouse",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/townhouse",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-townhouse-rental",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/townhouse/rental",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-condominium",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/condominium",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-condominium-rental",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/condominium/rental",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-villa",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/villa",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-villa-rental",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/villa/rental",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-multi-family-home",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/multi-family-home",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-multi-family-home-rental",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/multi-family-home/rental",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-houseboat",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/houseboat",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-houseboat-rental",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/houseboat/rental",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-agricultural",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/agricultural",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-agricultural-rental",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/agricultural/rental",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-land",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/land",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-land-rental",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/land/rental",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-home",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/home",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-home-rental",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/home/rental",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-boat-slip",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/boat-slip",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-deeded-parking",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/deeded-parking",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-apartment",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/apartment",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: single_45family_45home0OHAd5fr4pMeta?.name ?? "state-city-address-apartment-rental",
    path: single_45family_45home0OHAd5fr4pMeta?.path ?? "/:state/:city/:address/apartment/rental",
    meta: single_45family_45home0OHAd5fr4pMeta || {},
    alias: single_45family_45home0OHAd5fr4pMeta?.alias || [],
    redirect: single_45family_45home0OHAd5fr4pMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/[address]/single-family-home.vue").then(m => m.default || m)
  },
  {
    name: indexfuQwzuRM4OMeta?.name ?? "state-city-nc",
    path: indexfuQwzuRM4OMeta?.path ?? "/:state/:city/:nc",
    meta: indexfuQwzuRM4OMeta || {},
    alias: indexfuQwzuRM4OMeta?.alias || [],
    redirect: indexfuQwzuRM4OMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: mappBG2WZxxLKMeta?.name ?? "state-city-nc-map",
    path: mappBG2WZxxLKMeta?.path ?? "/:state/:city/:nc/map",
    meta: mappBG2WZxxLKMeta || {},
    alias: mappBG2WZxxLKMeta?.alias || [],
    redirect: mappBG2WZxxLKMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/map.vue").then(m => m.default || m)
  },
  {
    name: indexfuQwzuRM4OMeta?.name ?? "state-city-nc-rentals",
    path: indexfuQwzuRM4OMeta?.path ?? "/:state/:city/:nc/Rentals.php",
    meta: indexfuQwzuRM4OMeta || {},
    alias: indexfuQwzuRM4OMeta?.alias || [],
    redirect: indexfuQwzuRM4OMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: mappBG2WZxxLKMeta?.name ?? "state-city-nc-map-rentals",
    path: mappBG2WZxxLKMeta?.path ?? "/:state/:city/:nc/map/Rentals.php",
    meta: mappBG2WZxxLKMeta || {},
    alias: mappBG2WZxxLKMeta?.alias || [],
    redirect: mappBG2WZxxLKMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/map.vue").then(m => m.default || m)
  },
  {
    name: Comps_46phpT7XfwaIOLFMeta?.name ?? "state-city-nc-comps",
    path: Comps_46phpT7XfwaIOLFMeta?.path ?? "/:state/:city/:nc/Comps.php",
    meta: Comps_46phpT7XfwaIOLFMeta || {},
    alias: Comps_46phpT7XfwaIOLFMeta?.alias || [],
    redirect: Comps_46phpT7XfwaIOLFMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/Comps.php.vue").then(m => m.default || m)
  },
  {
    name: Comps_46phpT7XfwaIOLFMeta?.name ?? "state-city-nc-rc-comps",
    path: Comps_46phpT7XfwaIOLFMeta?.path ?? "/:state/:city/:nc/:rc/Comps.php",
    meta: Comps_46phpT7XfwaIOLFMeta || {},
    alias: Comps_46phpT7XfwaIOLFMeta?.alias || [],
    redirect: Comps_46phpT7XfwaIOLFMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/Comps.php.vue").then(m => m.default || m)
  },
  {
    name: indexfuQwzuRM4OMeta?.name ?? "state-city-nc-rc",
    path: indexfuQwzuRM4OMeta?.path ?? "/:state/:city/:nc/:rc",
    meta: indexfuQwzuRM4OMeta || {},
    alias: indexfuQwzuRM4OMeta?.alias || [],
    redirect: indexfuQwzuRM4OMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: mappBG2WZxxLKMeta?.name ?? "state-city-nc-rc-map",
    path: mappBG2WZxxLKMeta?.path ?? "/:state/:city/:nc/:rc/map",
    meta: mappBG2WZxxLKMeta || {},
    alias: mappBG2WZxxLKMeta?.alias || [],
    redirect: mappBG2WZxxLKMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/map.vue").then(m => m.default || m)
  },
  {
    name: indexfuQwzuRM4OMeta?.name ?? "state-city-nc-rc-rentals",
    path: indexfuQwzuRM4OMeta?.path ?? "/:state/:city/:nc/:rc/Rentals.php",
    meta: indexfuQwzuRM4OMeta || {},
    alias: indexfuQwzuRM4OMeta?.alias || [],
    redirect: indexfuQwzuRM4OMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: mappBG2WZxxLKMeta?.name ?? "state-city-nc-rc-map-rentals",
    path: mappBG2WZxxLKMeta?.path ?? "/:state/:city/:nc/:rc/map/Rentals.php",
    meta: mappBG2WZxxLKMeta || {},
    alias: mappBG2WZxxLKMeta?.alias || [],
    redirect: mappBG2WZxxLKMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/map.vue").then(m => m.default || m)
  },
  {
    name: indexOQSNWYveVLMeta?.name ?? "zipcode-rentals",
    path: indexOQSNWYveVLMeta?.path ?? "/zipcode/Rentals.php",
    meta: indexOQSNWYveVLMeta || {},
    alias: indexOQSNWYveVLMeta?.alias || [],
    redirect: indexOQSNWYveVLMeta?.redirect,
    component: () => import("~/pages/zipcode/index.vue").then(m => m.default || m)
  },
  {
    name: indext9NkWxHFcSMeta?.name ?? "zipcode-zip-rentals",
    path: indext9NkWxHFcSMeta?.path ?? "/zipcode/:zip/Rentals.php",
    meta: indext9NkWxHFcSMeta || {},
    alias: indext9NkWxHFcSMeta?.alias || [],
    redirect: indext9NkWxHFcSMeta?.redirect,
    component: () => import("~/pages/zipcode/[zip]/index.vue").then(m => m.default || m)
  },
  {
    name: mappBG2WZxxLKMeta?.name ?? "zipcode-zip-map",
    path: mappBG2WZxxLKMeta?.path ?? "/zipcode/:zip/map",
    meta: mappBG2WZxxLKMeta || {},
    alias: mappBG2WZxxLKMeta?.alias || [],
    redirect: mappBG2WZxxLKMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/map.vue").then(m => m.default || m)
  },
  {
    name: mappBG2WZxxLKMeta?.name ?? "zipcode-zip-map-rentals",
    path: mappBG2WZxxLKMeta?.path ?? "/zipcode/:zip/map/Rentals.php",
    meta: mappBG2WZxxLKMeta || {},
    alias: mappBG2WZxxLKMeta?.alias || [],
    redirect: mappBG2WZxxLKMeta?.redirect,
    component: () => import("~/pages/[state]/[city]/map.vue").then(m => m.default || m)
  }
]