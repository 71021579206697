import { cookieNames } from '~/utils/cookies';
import {
  isCommunityListPage,
  isCommunityMapPage,
  isCommunityRentalPage,
  isListingPage,
  isListingRentalPage,
  isStatePage,
  isStateRentalPage,
  isZipcodePage,
  isZipcodeRentalPage,
  pathConstants,
} from '~/utils/route-utils';

export const searchModes = {
  buy: 'buy',
  rent: 'rent',
};

export const state = () => ({
  searchMode: searchModes.buy,
});

export const getters = {
  isBuyMode(state) {
    return state.searchMode === searchModes.buy;
  },

  isRentMode(state) {
    return state.searchMode === searchModes.rent;
  },

  listingTypeId(state) {
    return state.searchMode === searchModes.buy ? 'sale' : state.searchMode;
  },
};

export const mutations = {
  SET_SEARCH_MODE(state, value) {
    state.searchMode = value;
  },
};

export const actions = {
  initialize({ commit }, { cookies, route }) {
    if (
      isCommunityRentalPage(route) ||
      isListingRentalPage(route) ||
      isStateRentalPage(route) ||
      isZipcodeRentalPage(route)
    ) {
      commit('SET_SEARCH_MODE', searchModes.rent);
    } else if (
      isStatePage(route) ||
      isCommunityListPage(route) ||
      isCommunityMapPage(route) ||
      isListingPage(route) ||
      isZipcodePage(route)
    ) {
      commit('SET_SEARCH_MODE', searchModes.buy);
    } else {
      const cookie = cookies.get(cookieNames.searchMode);
      commit('SET_SEARCH_MODE', cookie ?? searchModes.buy);
    }
  },

  setSearchMode({ commit }, searchMode) {
    commit('SET_SEARCH_MODE', searchMode);
  },

  redirectRentalToSalePages({ state }, router) {
    if (state.searchMode === searchModes.rent) {
      return;
    }

    const currentRoute = router.currentRoute.value;

    if (
      isStateRentalPage(currentRoute) ||
      isCommunityRentalPage(currentRoute) ||
      isZipcodeRentalPage(currentRoute)
    ) {
      if (currentRoute.path.endsWith('/')) {
        // we should never build Rentals.php urls ending with a slash, but if it does end with a slash,
        // handle redirection properly
        router.push(currentRoute.path.replace(`${pathConstants.communityRental}/`, ''));
      } else {
        router.push(currentRoute.path.replace(pathConstants.communityRental, ''));
      }
    }
  },

  redirectSaleToRentalPages({ state }, router) {
    const currentRoute = router.currentRoute.value;

    if (
      state.searchMode === searchModes.buy ||
      isStateRentalPage(currentRoute) ||
      isCommunityRentalPage(currentRoute) ||
      isZipcodeRentalPage(currentRoute)
    ) {
      return;
    }

    if (
      isStatePage(currentRoute) ||
      isCommunityListPage(currentRoute) ||
      isCommunityMapPage(currentRoute) ||
      isZipcodePage(currentRoute)
    ) {
      if (currentRoute.path.endsWith('/')) {
        router.push(`${currentRoute.path}${pathConstants.communityRental}`);
      } else {
        router.push(`${currentRoute.path}/${pathConstants.communityRental}`);
      }
    }
  },
};

export const namespaced = true;
