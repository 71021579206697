import { useContactLeadSalesService } from '~/composables/use-contact-lead-sales-service';
import { leadProgramStates, stateGraph } from '~/utils/lead-programs';
import { mutateStoreField } from '~/store/utils';

export const state = () => ({
  error: null,
  firstName: '',
  lastName: '',
  emailAddress: '',
  confirmEmailAddress: '',
  phoneNumber: '',
  leadTitle: '',
  leadType: '',
  referrer: '',
  referrerName: '',
  referrerNamePlaceholder: '',
  isReferrerNameVisible: false,
  statesCovered: '',
  citiesCovered: '',
  numberTransactions: '',
  licencedTimespan: '',
  brokerageName: '',
  step: stateGraph[leadProgramStates.start],
});

export const getters = {
  hasError(state) {
    return state.error !== null;
  },
  backStep(state) {
    return state.step.back ?? null;
  },
  currentStep(state) {
    return state.step.name;
  },
  nextStep(state) {
    return state.step.next ?? null;
  },
};

// Mutation types
const SET_ERROR = 'SET_ERROR';
const SET_FORM_FIELD = 'SET_FORM_FIELD';
const SET_LEAD_TITLE = 'SET_LEAD_TITLE';
const SET_REFERRER = 'SET_REFERRER';
const SET_STEP = 'SET_STEP';
const SHOW_REFERRER_NAME = 'SHOW_REFERRER_NAME';
const HIDE_REFERRER_NAME = 'HIDE_REFERRER_NAME';

export const types = {
  SET_ERROR,
  SET_FORM_FIELD,
  SET_LEAD_TITLE,
  SET_REFERRER,
  SET_STEP,
  SHOW_REFERRER_NAME,
  HIDE_REFERRER_NAME,
};

export const mutations = {
  [SET_ERROR](state, { error }) {
    state.error = error;
  },
  [SET_FORM_FIELD]: mutateStoreField,
  [SET_LEAD_TITLE](state, { value }) {
    state.leadTitle = value;
  },
  [SET_REFERRER](state, { value }) {
    state.referrer = value;
    state.referrerName = '';
  },
  [SET_STEP](state, { name }) {
    const step = name ? stateGraph[name] : stateGraph[leadProgramStates.start];
    state.step = step;
    if (step.leadType) {
      state.leadType = step.leadType;
    }
  },
  [SHOW_REFERRER_NAME](state, { placeholder }) {
    state.isReferrerNameVisible = true;
    state.referrerNamePlaceholder = placeholder;
  },
  [HIDE_REFERRER_NAME](state) {
    state.isReferrerNameVisible = false;
    state.referrerName = '';
    state.referrerNamePlaceholder = '';
  },
};

export const actions = {
  resetError({ commit }) {
    commit({ type: SET_ERROR, error: null });
  },
  selectLeadTitle({ commit }, value) {
    commit({ type: SET_LEAD_TITLE, value });
  },
  resetLeadTitle({ commit }) {
    commit({ type: SET_LEAD_TITLE, value: '' });
  },
  selectReferrer({ commit }, value) {
    commit({ type: SET_REFERRER, value });
  },
  setStep({ commit }, name) {
    commit({ type: SET_STEP, name });
  },
  showReferrerName({ commit }, placeholder) {
    commit({ type: SHOW_REFERRER_NAME, placeholder });
  },
  hideReferrerName({ commit }) {
    commit({ type: HIDE_REFERRER_NAME });
  },
  validateForm({ commit, state }, isReferralForm) {
    let error = null;

    if (!state.firstName) {
      error = 'First name is required';
    } else if (!state.lastName) {
      error = 'Last name is required';
    } else if (!state.emailAddress) {
      error = 'Email address is required';
    } else if (!state.confirmEmailAddress) {
      error = 'You must confirm your email address';
    } else if (state.emailAddress !== state.confirmEmailAddress) {
      error = 'Email addresses do not match';
    } else if (!state.phoneNumber) {
      error = 'Phone number is required';
    } else if (!state.leadTitle) {
      error = 'You must select which best represents you';
    } else if (!state.referrer) {
      error = 'You must select how you heard about us';
    }

    if (isReferralForm) {
      if (!state.numberTransactions) {
        error = 'Number of transactions is required';
      } else if (!state.licencedTimespan) {
        error = 'Number of years you have held license is required';
      } else if (!state.brokerageName) {
        error = 'Brokerage firm name is required';
      } else if (!state.statesCovered) {
        error = 'States covered is required';
      } else if (!state.citiesCovered) {
        error = 'Cities covered is required';
      }
    }

    commit({ type: SET_ERROR, error });
  },
  async submitForm({ commit, state }, captchaResponse) {
    commit({ type: SET_ERROR, error: null });

    if (!captchaResponse) {
      commit({ type: SET_ERROR, error: 'Please check the box to prove you are not a robot.' });
      return;
    }

    const { RequestTimer } = await import('~/services/models/request-timer');

    const data = {
      firstName: state.firstName,
      lastName: state.lastName,
      emailAddress: state.emailAddress,
      confirmEmailAddress: state.confirmEmailAddress,
      phoneNumber: state.phoneNumber,
      leadTitle: state.leadTitle,
      referrer: state.referrer,
      referrerName: state.referrerName,
      leadType: state.leadType,
      statesCovered: state.statesCovered,
      citiesCovered: state.citiesCovered,
      numberTransactions: state.numberTransactions,
      licencedTimespan: state.licencedTimespan,
      brokerageName: state.brokerageName,
      'g-recaptcha-response': captchaResponse,
    };

    try {
      await useContactLeadSalesService().sendContactMessage(data, new RequestTimer('confirm-send'), this.$sentry);
    } catch (error) {
      commit({ type: SET_ERROR, error: 'Something went wrong. Please try again.' });
    }
  }
};

export const namespaced = true;
