/** used for turning on verbose debug logging */
const logDebug = process.env.logDebug;

/**
 * Logs service errors to:
 *  - console if on server
 *  - sentry if on client
 * @param {String} serviceName name of the service
 * @param {String} serviceFuncName name of the callee function
 * @param {Error} error error to log
 * @param {Object} [$sentry=null] sentry instance
 */
function logError(serviceName, serviceFuncName, error, $sentry = null) {
  if (process.server) {
    console.error(`${serviceName}.${serviceFuncName}: [ERROR]: ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`);
  } else {
    $sentry?.captureException(error);
  }
}

/**
 * * Logs feathers errors to:
 *  - console if on server
 *  - sentry if on client
 * @param {String} requestId a uuid that uniquely represents the request
 * @param {String} timeLabel a timestamp
 * @param {Error} error error to log
 * @param {Object} [$sentry=null] sentry instance
 */
function logFeathersError(requestId, timeLabel, error, $sentry = null) {
  if (process.server) {
    console.error(`[${requestId}] ${timeLabel}: [ERROR]: ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`);
  } else {
    $sentry?.captureException(error);
  }
}

/**
 * Logs at the end of every feathers service request. To enable, you must change the bocaexec.json 'logDebug' config
 * @param {String} requestId a uuid that uniquely represents the request
 * @param {String} timeLabel a timestamp
 */
function logFeathersServiceEnd(requestId, timeLabel) {
  if (process.server && logDebug) {
    console.log(`[${requestId}] ${timeLabel}: end`);
  }
}

/**
 * Logs at the start of every feathers service request. To enable, you must change the bocaexec.json 'logDebug' config
 * @param {String} requestId a uuid that uniquely represents the request
 * @param {String} timeLabel a timestamp
 */
function logFeathersServiceStart(requestId, timeLabel) {
  if (process.server && logDebug) {
    console.log(`[${requestId}] ${timeLabel}: start`);
  }
}

function logInfo(namespace, message) {
  if (process.server) {
    console.log(`${namespace}: ${message}`);
  }
}

/**
 * Logs at the end of every service request. To enable, you must change the bocaexec.json 'logDebug' config
 * @param {String} requestId a uuid that uniquely represents the request
 * @param {String} timeLabel a timestamp
 */
function logServiceEnd(serviceName, serviceFuncName) {
  if (process.server && logDebug) {
    console.log(`${serviceName}.${serviceFuncName}: end`);
  }
}

/**
 * Logs at the start of every service request. To enable, you must change the bocaexec.json 'logDebug' config
 * @param {String} requestId a uuid that uniquely represents the request
 * @param {String} timeLabel a timestamp
 */
function logServiceStart(serviceName, serviceFuncName) {
  if (process.server && logDebug) {
    console.log(`${serviceName}.${serviceFuncName}: start`);
  }
}

export default {
  logError,
  logFeathersError,
  logFeathersServiceEnd,
  logFeathersServiceStart,
  logInfo,
  logServiceEnd,
  logServiceStart,
};
