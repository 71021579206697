export const transitions = {
  SLIDE_LEFT: 'slide-l',
  SLIDE_RIGHT: 'slide-r',
};

/** Used For UI, v-if */
export const leadProgramStates = {
  confirmMortgage: 'confirm-mortgage',
  confirmPurchase: 'confirm-purchase',
  confirmReferral: 'confirm-referral',
  confirmRental: 'confirm-rental',
  finish: 'finish',
  mortgageForm: 'mortgage-form',
  purchaseForm: 'purchase-form',
  referralForm: 'referral-form',
  rentalForm: 'rental-form',
  realEstateChoices: 'real-estate-choices',
  realEstateNurture: 'real-estate-nurture',
  realEstatePurchase: 'real-estate-purchase',
  realEstateReferral: 'real-estate-referral',
  start: 'start',
};

/** Used for distro and email formats */
export const leadProgramTypes = {
  /** leads are referred from marketing-landing.vue page */
  marketingLandingPage: 'real-estate-marketing-landing',
  /** mortgage broker */
  mortgage: 'mortgage',
  /** leads are nurtured by ccrs and cost a set amount per lead */
  realEstateNurture: 'real-estate-nurture',
  /** purchase leads from bex */
  realEstatePurchase: 'real-estate-purchase',
  /** referral partnership - standard 3pa program */
  realEstateReferralPartner: 'real-estate-referral-partner',
  /** RentReady - Courtesy Rental Leads program */
  rental: 'rental',
};

const colors = {
  BLUE: 'blue',
  GREEN: 'green',
  ORANGE: 'orange',
  PURPLE: 'purple',
};

export const stateGraph = {
  [leadProgramStates.confirmMortgage]: {
    color: colors.GREEN,
    back: leadProgramStates.mortgageForm,
    next: leadProgramStates.finish,
  },
  [leadProgramStates.confirmPurchase]: {
    color: colors.ORANGE,
    back: leadProgramStates.purchaseForm,
    next: leadProgramStates.finish,
  },
  [leadProgramStates.confirmReferral]: {
    color: colors.GREEN,
    back: leadProgramStates.referralForm,
    next: leadProgramStates.finish,
  },
  [leadProgramStates.confirmRental]: {
    color: colors.ORANGE,
    back: leadProgramStates.rentalForm,
    next: leadProgramStates.finish,
  },
  [leadProgramStates.finish]: {
    next: null,
  },
  [leadProgramStates.mortgageForm]: {
    color: colors.GREEN,
    back: leadProgramStates.start,
    next: leadProgramStates.confirmMortgage,
    leadType: leadProgramTypes.mortgage,
  },
  [leadProgramStates.purchaseForm]: {
    color: colors.ORANGE,
    back: leadProgramStates.realEstateChoices,
    next: leadProgramStates.confirmPurchase,
    leadType: leadProgramTypes.realEstatePurchase,
  },
  [leadProgramStates.referralForm]: {
    color: colors.GREEN,
    back: leadProgramStates.realEstateChoices,
    next: leadProgramStates.confirmReferral,
    leadType: leadProgramTypes.realEstateReferralPartner,
  },
  [leadProgramStates.rentalForm]: {
    color: colors.ORANGE,
    back: leadProgramStates.start,
    next: leadProgramStates.confirmRental,
    leadType: leadProgramTypes.rental,
  },
  [leadProgramStates.realEstateChoices]: {
    color: colors.BLUE,
    back: leadProgramStates.start,
  },
  [leadProgramStates.realEstateNurture]: {
    color: colors.PURPLE,
    back: leadProgramStates.realEstateChoices,
    next: null,
  },
  [leadProgramStates.realEstatePurchase]: {
    back: leadProgramStates.realEstateChoices,
    next: leadProgramTypes.purchaseForm,
  },
  [leadProgramStates.realEstateReferral]: {
    back: leadProgramStates.realEstateChoices,
    next: leadProgramTypes.referralForm,
  },
  [leadProgramStates.start]: {
    back: null,
  },
};

// Derive `name` property from each key of the graph
Object.entries(stateGraph).forEach(([k, v]) => v.name = k);

// Map each step to its color
const stepColors = Object.entries(stateGraph).reduce((acc, [name, { color }]) => {
  if (color) {
    acc.set(name, color);
  }
  return acc;
}, new Map());

/**
 * Safely returns the color associated with a step.
 *
 * @param {string} name the name of the step
 * @returns the color of the step or an empty string if it's undefined
 */
export function getStepColor(name) {
  return stepColors.get(name) ?? '';
}
