import FeathersService from './base/feathers-service';

export class RecentCommunityService extends FeathersService {
  createRecentCommunity(data, timer) {
    return this.throwableRequest(async (query) => {
      const result = await this.service.create(data, { query });
      return result;
    }, 'createRecentCommunity', timer);
  }
}
