/**
 * Format given url, append query to url query string
 *
 * @param  {string} url
 * @param  {string} query
 * @return {string}
 */
function formatUrl(url, query) {
  let protocol
  const index = url.indexOf('://')
  if (index !== -1) {
    protocol = url.substring(0, index)
    if (protocol === 'http') {
      protocol = 'https'
    }
    url = url.substring(index + 3)
  } else if (url.startsWith('//')) {
    url = url.substring(2)
  }

  let parts = url.split('/')
  let result = (protocol ? protocol + '://' : '//') + parts.shift()

  let path = parts.filter(Boolean).join('/')
  let hash
  parts = path.split('#')
  if (parts.length === 2) {
    path = parts[0]
    hash = parts[1]
  }

  result += path ? '/' + path : ''

  if (query && JSON.stringify(query) !== '{}') {
    result += (url.split('?').length === 2 ? '&' : '/?') + formatQuery(query)
  }
  result += hash ? '#' + hash : ''

  return result
}

/**
 * Transform data object to query string
 *
 * @param  {object} query
 * @return {string}
 */
function formatQuery(query) {
  return Object.keys(query).sort().map((key) => {
    const val = query[key]
    if (val == null) {
      return ''
    }
    if (Array.isArray(val)) {
      return val.slice().map(val2 => [key, '=', val2].join('')).join('&')
    }
    return key + '=' + val
  }).filter(Boolean).join('&')
}

function prependProtocolToUrl(url, protocol = 'http://') {
  let safeUrl = url || ''

  // Check whether url starts with http:// or https://
  if (!/^https?:\/\//i.test(safeUrl)) {
    safeUrl = protocol + safeUrl;
  }

  /**
   * RegEx checks if URL is valid.
   * 1. Checks first for one instance of http://www, https://www, http://, https:// or www
   *    and must be in beginning of string
   * 2. Matches "a" to "z", "A" to "Z" and/or "0" to "9" after #1
   * 3. After letters or numbers check in #2, must contain either "-" or "." only once
   * 4. Then must contain at least 2 characters of "a" to "z", "A" to "Z" and/or "0" to "9"
   * 5. After the above passes, special characters and patterns accepted
   */
  const validUrl = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/igm);
  const urlPassedCheck = safeUrl.match(validUrl);

  return urlPassedCheck ? urlPassedCheck[0] : null;
}

export default {
  formatUrl,
  formatQuery,
  prependProtocolToUrl
}
