import type { RouterConfig } from '@nuxt/schema';

export default <RouterConfig> {
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (routes) => [
    ...routes,
    {
      name: 'catch-all',
      path: '/:state/:catchAll(.*)*',
      component: () => import('~/error.vue'),
      props: {
        error: {
          statusCode: 404
        },
      },
    },
  ],
};
