<template>
  <div class="loading-page" v-if="loading">
    <div class="loading-position">
      <i class="loadbar" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default defineComponent({
  name: 'loading-indicator',
  computed: {
    ...mapState('loading', ['loading']),
  },
});
</script>

<style lang="less" scoped>
.loading {
  &-page {
    position: absolute;
    width: 100%;
    z-index: @loader;
    height: 8px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    top: 0;
  }
  &-position {
    position: absolute;
    z-index: @loader;
    top: 0;
  }
}

@keyframes loadbaralt {
  0%,to { left: 0; right: 100% }
  50% { left: 0; right: 0 }
  100% { left: 100%; right: 0 }
}
.loadbar {
  display: block;
  box-sizing: border-box;
  height: 4px;
  border-radius: 4px;
  position: relative;
  transform: scale(var(--ggs,1.5));
  width: 100vw;
  &::before {
    display: block;
    box-sizing: border-box;
    height: 4px;
    border-radius: 4px;
    background: @bex-blue;
    content: "";
    position: absolute;
    animation: loadbaralt 2.5s cubic-bezier(0,0,.58,1) infinite;
  }
  &::after {
    display: block;
    box-sizing: border-box;
    height: 4px;
    border-radius: 4px;
    background: @bex-blue;
    content: "";
    position: absolute;
    width: 100vw;
    opacity: .3;
  }
}
</style>
