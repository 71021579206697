import { CookieStorage } from 'cookie-storage';
import auth from '@feathersjs/authentication-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';

export default defineNuxtPlugin({
  parallel: true,
  setup() {
    const socket = io(process.env.apiBaseUrl!, {
      path: `${process.env.defaultApiPath}ws`,
      transports: ['websocket'],
    });

    const app = feathers();

    app.configure(socketio(socket, {
      timeout: 10000,
    }));

    app.configure(auth({
      path: `${process.env.defaultApiPath}/authentication`,
      storage: new CookieStorage({
        path: '/',
      }),
    }));

    // Handle socket re-connections
    socket.on('reconnect', async () => {
      try {
        await app.reAuthenticate(true);
      } catch (err: any) {
        if (err.className !== 'not-authenticated') {
          console.error(err);
        }
      }
    });

    return {
      provide: {
        feathers: app,
      },
    };
  },
});
