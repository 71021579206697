export const routeNames = {
  agentEmail: 'agents-email',
  catchAll: 'catch-all',
  home: 'index',
  sitemaps: 'sitemaps',
  resources: 'resources',
  resourcesCalculators: 'resources-calculators',
  resourcesGetPreApproved: 'resources-get-pre-approved',
  resourcesInsuranceRates: 'resources-insurance-rates',
  resourcesMortgageRates: 'resources-mortgage-rates',
  sitemapsCity: 'sitemaps-city',
  sitemapsNc: 'sitemaps-nc',
  sitemapsState: 'sitemaps-state',
  sitemapsStateNewListings: 'sitemaps-state-new-listings',
  sitemapsStateNewRentalListings: 'sitemaps-state-new-rental-listings',
  sitemapsStateZipcode: 'sitemaps-state-zipcode',
  sitemapsStateZipcodeRentals: 'sitemaps-state-zipcode-rentals',
  stateCityAddressAgricultural: 'state-city-address-agricultural',
  stateCityAddressAgriculturalRental: 'state-city-address-agricultural-rental',
  stateCityAddressApartment: 'state-city-address-apartment',
  stateCityAddressApartmentRental: 'state-city-address-apartment-rental',
  stateCityAddressBoatSlip: 'state-city-address-boat-slip',
  stateCityAddressCondominium: 'state-city-address-condominium',
  stateCityAddressCondominiumRental: 'state-city-address-condominium-rental',
  stateCityAddressDeededParking: 'state-city-address-deeded-parking',
  stateCityAddressHome: 'state-city-address-home',
  stateCityAddressHomeRental: 'state-city-address-home-rental',
  stateCityAddressHouseboat: 'state-city-address-houseboat',
  stateCityAddressHouseboatRental: 'state-city-address-houseboat-rental',
  stateCityAddressLand: 'state-city-address-land',
  stateCityAddressLandRental: 'state-city-address-land-rental',
  stateCityAddressMultiFamilyHome: 'state-city-address-multi-family-home',
  stateCityAddressMultiFamilyHomeRental: 'state-city-address-multi-family-home-rental',
  stateCityAddressSingleFamilyHome: 'state-city-address-single-family-home',
  stateCityAddressSingleFamilyHomeRental: 'state-city-address-single-family-home-rental',
  stateCityAddressTownhouse: 'state-city-address-townhouse',
  stateCityAddressTownhouseRental: 'state-city-address-townhouse-rental',
  stateCityAddressVilla: 'state-city-address-villa',
  stateCityAddressVillaRental: 'state-city-address-villa-rental',
  state: 'state',
  stateRentals: 'state-rentals',
  stateCityComps: 'state-city-Comps.php',
  stateCityMapRentals: 'state-city-map-rentals',
  stateCityNc: 'state-city-nc',
  stateCityNcComps: 'state-city-nc-comps',
  stateCityNcMap: 'state-city-nc-map',
  stateCityNcMapRentals: 'state-city-nc-map-rentals',
  stateCityNcRc: 'state-city-nc-rc',
  stateCityNcRcComps: 'state-city-nc-rc-comps',
  stateCityNcRcMap: 'state-city-nc-rc-map',
  stateCityNcRcMapRentals: 'state-city-nc-rc-map-rentals',
  stateCityNcRcRentals: 'state-city-nc-rc-rentals',
  stateCityNcRentals: 'state-city-nc-rentals',
  stateCity: 'state-city',
  stateCityMap: 'state-city-map',
  stateCityRentals: 'state-city-rentals',
  zipcode: 'zipcode',
  zipcodeRentals: 'zipcode-rentals',
  zipcodeZip: 'zipcode-zip',
  zipcodeZipMap: 'zipcode-zip-map',
  zipcodeZipMapRentals: 'zipcode-zip-map-rentals',
  zipcodeZipRentals: 'zipcode-zip-rentals',
} as const;
