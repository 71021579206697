import FeathersService from '~/services/base/feathers-service';

export class LegacySavedPropertyService extends FeathersService {
  /**
   * Get saved properties by userId
   * @param {String} userId
   * @param {RequestTimer} requestTimer
   * @param {Object} $sentry
   */
  async getSavedProperties(userId, requestTimer, $sentry) {
    return await super.throwableRequest(async (baseQuery) => {
      const result = await this.service.find({
        query: {
          ...baseQuery,
          $limit: 50,
          $select: [
            'propertyListingId'
          ],
          username: userId
        }
      });

    const data = result.data || [];
    return data;
    }, 'getSavedProperties', requestTimer, $sentry);
  }

  /**
   * Create saved property record for user
   * @param {Object} savedProperty
   * @param {RequestTimer} requestTimer
   * @param {Object} $sentry
   */
  async create(savedProperty, requestTimer, $sentry) {
    return await super.throwableRequest(async (baseQuery) => {
      const mergedProperty = Object.assign(baseQuery, savedProperty);
      await this.service.create(mergedProperty);
    }, 'create', requestTimer, $sentry);
  }

  /**
   * Remove a saved property record for user
   * @param {Object} savedProperty
   * @param {String} savedProperty.username required
   * @param {String} savedProperty.propertyListingId required
   * @param {RequestTimer} requestTimer
   * @param {Object} $sentry
   */
  async remove(savedProperty, requestTimer, $sentry) {
    return await super.throwableRequest(async (baseQuery) => {
      if (!savedProperty.username) {
        throw new Error(`username is required to remove a saved property`);
      }

      if (!savedProperty.propertyListingId) {
        throw new Error(`propertyListingId is required to remove a saved property`);
      }

      const mergedProperty = Object.assign(baseQuery, savedProperty);

      // Since there's a chance a user will have multiple entries for the same
      // property listing ID, we need to leverage the multi removal feature by
      // passing in a `null` ID so that we can use a where clause to remove all
      // entries matching a given user and listing.
      await this.service.remove(null, {
        query: mergedProperty,
      });
    }, 'remove', requestTimer, $sentry);
  }
}
