import validate from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/validate.js";
import login_45location_45cookie_45global from "/home/circleci/project/middleware/login-location-cookie.global.js";
import refer_45source_45cookie_45global from "/home/circleci/project/middleware/refer-source-cookie.global.js";
import manifest_45route_45rule from "/home/circleci/project/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  login_45location_45cookie_45global,
  refer_45source_45cookie_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}