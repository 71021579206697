export const localStorageProxy = {
  getItem(key) {
    try {
      return window.localStorage.getItem(key);
    } catch {
      // Return null to mimic behavior of successful call on non-existent key
      // https://developer.mozilla.org/en-US/docs/Web/API/Storage/getItem
      return null;
    }
  },

  setItem(key, value) {
    try {
      window.localStorage.setItem(key, value);
    } catch {
      // swallow error
    }
  },

  removeItem(key) {
    try {
      window.localStorage.removeItem(key);
    } catch {
      // swallow error
    }
  },

  getJSON(key) {
    return JSON.parse(this.getItem(key));
  },

  setJSON(key, value) {
    this.setItem(key, JSON.stringify(value));
  },
};
