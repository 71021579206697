import { routeNames } from '~/utils/route-names';
import { communityTreeLevels } from '~/utils/community-types';
import { decodeViewport } from '~/utils/map-viewport';
import urlFormat from '~/utils/url-format';

/**
 * constants for paths
 *
 * note: avoid slashes in path constants so consumers can add slashes where needed
 */
export const pathConstants = {
  communityRental: 'Rentals.php',
  comps: 'Comps.php',
  listingRental: 'rental',
  map: 'map',
  sitemaps: 'sitemaps',
  sitemapsNewListingsForSale: 'new-listings',
  sitemapsNewListingsForRent: 'new-rental-listings',
  zipcode: 'zipcode',
};

/**
 * Clears all map specific query parameters
 * @param {Object} originalQuery
 */
export function clearMapQuery(originalQuery) {
  const query = Object.assign({}, originalQuery);

  delete query.viewport;
  delete query.mapPoly;
  delete query.zoom;

  return query;
}

/**
 * Builds a url to a community list page, from a map page, that preserves all query paraemters,
 * expect map specific query parameters
 * @param {Object} route nuxt route
 */
export function getListRedirectUrl(route) {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const regex = new RegExp(`${pathConstants.map}/?`);
  const path = route.path.replace(regex, '');

  const query = clearMapQuery(route.query);
  const formattedQuery = urlFormat.formatQuery(query);

  const pathWithQuery = formattedQuery ? `${path}?${formattedQuery}` : path;

  return pathWithQuery;
}

/**
 * Builds a url to a map page, from a community list page, that preserves all query parameters
 * @param {Object} route nuxt route
 */
export function getMapRedirectUrl(route) {
  const { path, query } = route;

  const mapPath = pathConstants.map;
  const rentalPath = pathConstants.communityRental;

  // path already has map param appended, prevent from adding it again
  if (path.endsWith(`${mapPath}/`) || path.endsWith(`${mapPath}/${rentalPath}`)) {
    return path;
  }

  let redirectUrl = '';
  if (path.endsWith(rentalPath) || path.endsWith(`${rentalPath}/`)) {
    redirectUrl = path.replace(rentalPath, `${mapPath}/${rentalPath}`)
  } else if (path.endsWith('/')) {
    redirectUrl = `${path}${mapPath}/`
  } else {
    redirectUrl = `${path}/${mapPath}/`
  }

  if (query) {
    const urlParams = Object.keys(query)
      .map(key => `${key}=${query[key]}`)
      .join('&');

    redirectUrl += `?${urlParams}`;
  }

  return redirectUrl;
}

/**
 * Returns true if the current route is on the agent profile page
 * @param {Object} route nuxt route
 */
export function isAgentProfilePage(route) {
  return route.name === routeNames.agentEmail;
}

/**
 * Returns true if the current route is on the mortgage calculator page
 * @param {Object} route nuxt route
 */
export function isCalculatorPage(route) {
  return route.name === routeNames.resourcesCalculators;
}

/**
 * Returns true if the current route is of a community list page
 * @param {Object} route nuxt route
 */
export function isCommunityListPage(route) {
  const result = [
    'state-city',
    'state-city-nc',
    'state-city-nc-rc',
    'state-city-rentals',
    'state-city-nc-rentals',
    'state-city-nc-rc-rentals'
  ].indexOf(route.name) !== -1;

  return result;
}

/**
 * Returns true if the current route is of a community map page
 * @param {Object} route nuxt route
 */
export function isCommunityMapPage(route) {
  const result = [
    'state-city-map',
    'state-city-nc-map',
    'state-city-nc-rc-map',
    'state-city-map-rentals',
    'state-city-nc-map-rentals',
    'state-city-nc-rc-map-rentals'
  ].indexOf(route.name) !== -1;

  return result;
}

/**
 * Returns true if the current route is of a community rental page
 * @param {Object} route nuxt route
 */
export function isCommunityRentalPage(route) {
  const result = [
    'state-city-rentals',
    'state-city-nc-rentals',
    'state-city-nc-rc-rentals',
    'state-city-map-rentals',
    'state-city-nc-map-rentals',
    'state-city-nc-rc-map-rentals'
  ].indexOf(route.name) !== -1;

  return result;
}

export function isListingPage(route) {
  return [
    routeNames.stateCityAddressAgricultural,
    routeNames.stateCityAddressAgriculturalRental,
    routeNames.stateCityAddressApartment,
    routeNames.stateCityAddressBoatSlip,
    routeNames.stateCityAddressApartmentRental,
    routeNames.stateCityAddressCondominium,
    routeNames.stateCityAddressCondominiumRental,
    routeNames.stateCityAddressDeededParking,
    routeNames.stateCityAddressHome,
    routeNames.stateCityAddressHomeRental,
    routeNames.stateCityAddressHouseboat,
    routeNames.stateCityAddressHouseboatRental,
    routeNames.stateCityAddressLand,
    routeNames.stateCityAddressLandRental,
    routeNames.stateCityAddressMultiFamilyHome,
    routeNames.stateCityAddressMultiFamilyHomeRental,
    routeNames.stateCityAddressSingleFamilyHome,
    routeNames.stateCityAddressSingleFamilyHomeRental,
    routeNames.stateCityAddressTownhouse,
    routeNames.stateCityAddressTownhouseRental,
    routeNames.stateCityAddressVilla,
    routeNames.stateCityAddressVillaRental,
  ].includes(route.name);
}

/**
 * Returns true if the current route is a rental listing page
 * @param {Object} route nuxt route
 */
 export function isListingRentalPage(route) {
  const listingRentalRoutes = [
    routeNames.stateCityAddressAgriculturalRental,
    routeNames.stateCityAddressApartmentRental,
    routeNames.stateCityAddressCondominiumRental,
    routeNames.stateCityAddressHomeRental,
    routeNames.stateCityAddressHouseboatRental,
    routeNames.stateCityAddressLandRental,
    routeNames.stateCityAddressMultiFamilyHomeRental,
    routeNames.stateCityAddressSingleFamilyHomeRental,
    routeNames.stateCityAddressTownhouseRental,
    routeNames.stateCityAddressVillaRental,
  ];

  const result = listingRentalRoutes.includes(route.name);
  return result;
}

export function isSitemapZipcodeRentalPage(route) {
  return route.name === routeNames.sitemapsStateZipcodeRentals;
}

export function isStatePage(route) {
  return route.name === 'state' || route.name === routeNames.stateRentals;
}

export function isStateRentalPage(route) {
  const result = route.name === routeNames.stateRentals;
  return result;
}

/**
 * Returns true if the current route is a sitemap new rental listing page
 * @param {Object} route nuxt route
 */
export function isNewListingSitemapRentalPage(route) {
  const result = routeNames.sitemapsStateNewRentalListings === route.name;
  return result;
}

export function isZipcodePage(route) {
  return [
    routeNames.zipcode,
    routeNames.zipcodeZip,
    routeNames.zipcodeZipMap,
   ].includes(route.name);
}

export function isZipcodeRentalPage(route) {
  return [
    routeNames.zipcodeRentals,
    routeNames.zipcodeZipRentals,
    routeNames.zipcodeZipMapRentals,
   ].includes(route.name);
}

/**
 * Returns true if the current route is a page listed below
 * used to dismiss `complete-registration-modal` on these pages
 * @param {Object} route nuxt route
 */
export function hideCompleteRegModalPage(route) {
  const result = [
    'terms.php',
    'privacy.php',
    'notices-dmca',
    'contact.php',
    'realtors-join-us',
  ].indexOf(route.name) !== -1;

  return result;
}

/**
 * Parses route path to get the following data:
 *  - isMapQuery: true if query parameters indicate that we should be querying for map results
 *  - viewport: the viewport boundaries of the map
 *  - mapPoly: a custom polygon drawn by a user
 *  - zoom: the zoom level of the map
 * @param {Object} route nuxt route
 */
export function parseMapRoute(route) {
  const isMapQuery = route.query.viewport || route.query.mapPoly ? true : false;
  const rawViewport = route.query.viewport;
  const mapPoly = route.query.mapPoly;
  const zoom = Number.isNaN(Number(route.query.zoom)) ? undefined : Number(route.query.zoom);

  let viewport = undefined;
  if (rawViewport) {
    viewport = decodeViewport(rawViewport)
  }

  return {
    isMapQuery,
    viewport,
    mapPoly,
    zoom,
  };
}

/**
 * Parses route path to get the follow data:
 *  - search path
 *  - community tree level
 * @param {Object} route nuxt route
 */
export function parseRoutePath(route) {
  const stateSegment = route.params.state;
  const citySegment = route.params.city;
  const ncSegment = route.params.nc; // neighborhood, development, condominium, townhouse, apartment
  const rcSegment = route.params.rc; // subdivision, building, condominium (within development), townhouse (within development)

  let searchPath = '';
  let communityTreeLevel = communityTreeLevels.root;

  if (stateSegment) {
    searchPath = `/${stateSegment}`;
    communityTreeLevel = communityTreeLevels.state;
  }

  if (citySegment) {
    searchPath += `/${citySegment}`;
    communityTreeLevel = communityTreeLevels.city;
  }

  if (ncSegment) {
    searchPath += `/${ncSegment}`;
    communityTreeLevel = communityTreeLevels.nc;
  }

  if (rcSegment) {
    searchPath += `/${rcSegment}`;
    communityTreeLevel = communityTreeLevels.rc;
  }

  if (!searchPath.endsWith('.php')) {
    searchPath += '/';
  }

  return { searchPath, communityTreeLevel };
}
