import { useCookieUniversal } from '~/composables/use-cookie-universal';
import { cookieNames, getExpirationDateBySeconds } from '~/utils/cookies'

/**
 * Adds a "refersource" cookie to each page since the legacy tracks the
 * conversion page (user3) using this cookie when users sign up.
 *
 * Also adds the "g-adword-gclid" cookie, whose value is appended to the
 * refersource URL in order to track AdWord conversions,
 *
 * Note: the "gclid" query string parameter is present every time a user
 * enters through a Google AdWord click.
 */
export default defineNuxtRouteMiddleware((to) => {
  const cookies = useCookieUniversal();

  const { gclid } = to.query;

  // there is a gclid query parameter, store it in a cookie for subsequent requests
  if (gclid) {
    const oneMonthFromNow = getExpirationDateBySeconds(2592000);
    cookies.set(cookieNames.googleAdwordGclid, {
      gclid
    }, {
      expires: oneMonthFromNow,
      path: '/',
      sameSite: 'Lax',
    });
  }

  const gclidCookie = cookies.get(cookieNames.googleAdwordGclid);

  let currentLocation;
  if (gclidCookie && gclidCookie.gclid) {
    // append the gclid to the refersource cookie url
    const query = { ...to.query };
    query['gclid'] = gclidCookie.gclid;

    // build the url query parameters
    const queryParams = Object.keys(query)
      .map(key => `${key}=${query[key]}`)
      .join('&');

    currentLocation = `${process.env.wwwBaseUrl}${to.path}?${queryParams}`;
  } else {
    currentLocation = process.env.wwwBaseUrl + to.fullPath;
  }

  cookies.set(cookieNames.referSource, currentLocation);
});
