import logger from '~/services/logger/service-logger';

/** The base of every service */
class BaseService {
  constructor(apiServiceName) {
    this.name = apiServiceName;
  }

  /**
   * A service request
   * @param {Function} serviceFunc function used for calling services
   * @param {String} serviceFuncName a label for the name of the function being run
   * @param {*} [defaultReturn=null] what gets returned if the serviceFunc throws an error
   * @param {Object} [$sentry=null] sentry instance
   * @returns result of serviceFunc
   */
  async request(serviceFunc, serviceFuncName, defaultReturn = null, $sentry = null) {
    try {
      logger.logServiceStart(this.name, serviceFuncName);
      return await serviceFunc();
    } catch (e) {
      logger.logError(this.name, serviceFuncName, e, $sentry);
      return defaultReturn;
    } finally {
      logger.logServiceEnd(this.name, serviceFuncName);
    }
  }

  /**
   * A synchronous service request
   * @param {Function} serviceFunc function used for calling services
   * @param {String} serviceFuncName a label for the name of the function being run
   * @param {*} [defaultReturn=null] what gets returned if the serviceFunc throws an error
   * @param {Object} [$sentry=null] sentry instance
   * @returns result of serviceFunc
   */
  requestSync(serviceFunc, serviceFuncName, defaultReturn = null, $sentry = null) {
    try {
      logger.logServiceStart(this.name, serviceFuncName);
      return serviceFunc();
    } catch (e) {
      logger.logError(this.name, serviceFuncName, e, $sentry);
      return defaultReturn;
    } finally {
      logger.logServiceEnd(this.name, serviceFuncName);
    }
  }

  /**
   * A service request that throws errors if serviceFunc errors
   * @param {Function} serviceFunc function used for calling services
   * @param {String} serviceFuncName a label for the name of the function being run
   * @param {Object} [$sentry=null] sentry instance
   * @returns result of serviceFunc
   */
  async throwableRequest(serviceFunc, serviceFuncName, $sentry = null) {
    try {
      logger.logServiceStart(this.name, serviceFuncName);
      return await serviceFunc();
    } catch (e) {
      logger.logError(this.name, serviceFuncName, e, $sentry);
      throw e;
    } finally {
      logger.logServiceEnd(this.name, serviceFuncName);
    }
  }

  /**
   * A service request that throws errors if serviceFunc errors
   * @param {Function} serviceFunc function used for calling services
   * @param {String} serviceFuncName a label for the name of the function being run
   * @param {Object} [$sentry=null] sentry instance
   * @returns result of serviceFunc
   */
   throwableRequestSync(serviceFunc, serviceFuncName, $sentry = null) {
    try {
      logger.logServiceStart(this.name, serviceFuncName);
      return serviceFunc();
    } catch (e) {
      logger.logError(this.name, serviceFuncName, e, $sentry);
      throw e;
    } finally {
      logger.logServiceEnd(this.name, serviceFuncName);
    }
  }
}

export default BaseService;
