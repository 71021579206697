import revive_payload_client_4sVQNw7RlN from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/circleci/project/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/circleci/project/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analytics_client_mZYnNRQBH8 from "/home/circleci/project/plugins/analytics.client.js";
import apple_auth_client_HCpAbycI2Q from "/home/circleci/project/plugins/apple-auth.client.js";
import cookie_universal_RxwatYAEKT from "/home/circleci/project/plugins/cookie-universal.ts";
import feathers_client_Ni76odHEEj from "/home/circleci/project/plugins/feathers.client.ts";
import google_auth_client_SRQ4B52Clm from "/home/circleci/project/plugins/google-auth.client.js";
import google_maps_isOUeleMUR from "/home/circleci/project/plugins/google-maps.js";
import observe_visibility_Bz8BIFRm65 from "/home/circleci/project/plugins/observe-visibility.js";
import request_metadata_VYJnglgyww from "/home/circleci/project/plugins/request-metadata.ts";
import sentry_client_shVUlIjFLk from "/home/circleci/project/plugins/sentry.client.ts";
import touch_events_x133J2zR3A from "/home/circleci/project/plugins/touch-events.js";
import vuex_F8JCgj45hr from "/home/circleci/project/plugins/vuex.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  analytics_client_mZYnNRQBH8,
  apple_auth_client_HCpAbycI2Q,
  cookie_universal_RxwatYAEKT,
  feathers_client_Ni76odHEEj,
  google_auth_client_SRQ4B52Clm,
  google_maps_isOUeleMUR,
  observe_visibility_Bz8BIFRm65,
  request_metadata_VYJnglgyww,
  sentry_client_shVUlIjFLk,
  touch_events_x133J2zR3A,
  vuex_F8JCgj45hr
]