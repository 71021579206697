export const appHead = {"meta":[{"charset":"utf-8"},{"http-equiv":"x-ua-compatible","content":"ie=edge"},{"name":"p:domain_verify","content":"94423045783942bbaad1a4837403d6de"},{"name":"theme-color","content":"#152038"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:site","content":"@bocaexecutive"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"apple-itunes-app","content":"app-id=1630942593, app-argument=https://apps.apple.com/us/app/bex-realty/id1630942593"},{"property":"og:description","content":"","name":"description","hid":"description"},{"property":"og:type","content":"website","hid":"type"},{"property":"og:image","content":"https://www.bexrealty.com/logo.jpg","hid":"image"},{"property":"fb:app_id","content":"158664327497674"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"},{"rel":"preconnect","href":"https://images-prd.bexrealty.com"},{"rel":"preconnect","href":"https://www.googletagmanager.com"},{"rel":"preconnect","href":"https://www.google-analytics.com"},{"rel":"preload","href":"/fonts/poppins-400-webfont.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"},"title":"BEX Realty - Real Estate, Homes, Condos and Townhouses for Sale or for Rent"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false