import { useCanonicalPath } from '~/composables/use-canonical-path';

export interface UseGenericHeadInput {
  title: string;
  description: string;
}

export function useGenericHead({ title, description }: UseGenericHeadInput) {
  const canonicalPath = useCanonicalPath();

  return useHead({
    title,
    link: [
      { rel: 'canonical', href: canonicalPath, hid: 'canonical' },
    ],
    meta: [
      { name: 'description', content: description },
      { property: 'og:url', content: canonicalPath, hid: 'url' },
      { property: 'og:description', name: 'Description', content: description, hid: 'description' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'robots', content: 'max-image-preview:large' },
    ],
  });
}
