// https://github.com/getsentry/sentry/blob/master/src/sentry/templates/sentry/js-sdk-loader.ts
// https://github.com/nuxt-community/sentry-module/blob/main/src/templates/plugin.lazy.js
// https://github.com/Daniel15/LazySentry/blob/main/LazySentry.ts
type SentryVue = typeof import('@sentry/vue');

type Sentry = {
  addBreadcrumb: SentryVue['addBreadcrumb'];
  captureException: SentryVue['captureException'];
  captureMessage: SentryVue['captureMessage'];
  setExtra: SentryVue['setExtra'];
  setUser: SentryVue['setUser'];
  showReportDialog: SentryVue['showReportDialog'];
}

type ErrorQueueItem = { error: ErrorEvent };
type PromiseRejectionQueueItem = { rejection: PromiseRejectionEvent };
type SentryCallQueueItem = { call: (sentry: Sentry) => void };
type QueueItem = ErrorQueueItem | PromiseRejectionQueueItem | SentryCallQueueItem;

function isError(item: QueueItem): item is ErrorQueueItem {
  return Object.hasOwn(item, 'error');
}

function isPromiseRejection(item: QueueItem): item is PromiseRejectionQueueItem {
  return Object.hasOwn(item, 'rejection');
}

function isSentryCall(item: QueueItem): item is QueueItem {
  return Object.hasOwn(item, 'call');
}

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    const config = useRuntimeConfig();

    let queue: QueueItem[] | null = [];

    function enqueueError(event: ErrorEvent) {
      queue?.push({ error: event });
    }

    function enqueuePromiseRejection(event: PromiseRejectionEvent) {
      queue?.push({ rejection: event });
    }

    // Capture any errors and unhandled rejections so they can be replayed later
    window.addEventListener('error', enqueueError);
    window.addEventListener('unhandledrejection', enqueuePromiseRejection);

    // Mock Sentry methods so their calls can be enqueued and replayed once the
    // SDK is initialized
    const sentry: Sentry = {
      addBreadcrumb: (...args) => {
        queue?.push({ call: (s) => s.addBreadcrumb(...args) });
      },
      captureException: (...args) => {
        queue?.push({ call: (s) => s.captureException(...args) });
        return '';
      },
      captureMessage: (...args) => {
        queue?.push({ call: (s) => s.captureMessage(...args) });
        return '';
      },
      setExtra: (...args) => {
        queue?.push({ call: (s) => s.setExtra(...args) });
      },
      setUser: (...args) => {
        queue?.push({ call: (s) => s.setUser(...args) });
      },
      showReportDialog: (...args) => {
        queue?.push({ call: (s) => s.showReportDialog(...args) });
      },
    };

    // Hook into the global error handler, capturing the error with Sentry but
    // ensuring that any preexisting handler still gets invoked
    const vueErrorHandler = nuxtApp.vueApp.config.errorHandler;

    nuxtApp.vueApp.config.errorHandler = (err, instance, info) => {
      instance?.$sentry.captureException(err);
      vueErrorHandler?.(err, instance, info);
    };

    // Load Sentry and initialize it on the next idle callback so it doesn't
    // block the initial rendering of the app.
    // https://nuxt.com/docs/api/utils/on-nuxt-ready
    onNuxtReady(async () => {
      const {
        init,
        addBreadcrumb,
        captureException,
        captureMessage,
        setExtra,
        setUser,
        showReportDialog,
        breadcrumbsIntegration,
        browserApiErrorsIntegration,
        debugIntegration,
        dedupeIntegration,
        extraErrorDataIntegration,
        functionToStringIntegration,
        globalHandlersIntegration,
        httpContextIntegration,
        inboundFiltersIntegration,
        linkedErrorsIntegration,
        reportingObserverIntegration,
        thirdPartyErrorFilterIntegration,
        isFilteredEvent,
      } = await import('~/utils/sentry');

      init({
        app: nuxtApp.vueApp,
        dsn: config.public.sentry.dsn,
        environment: config.public.sentry.environment,
        integrations() {
          const integrations = [
            // Mimic default and Nuxt 2 module integrations
            // https://docs.sentry.io/platforms/javascript/configuration/integrations/
            // https://sentry.nuxtjs.org/configuration/options#clientintegrations
            breadcrumbsIntegration(),
            browserApiErrorsIntegration(),
            dedupeIntegration(),
            functionToStringIntegration(),
            globalHandlersIntegration(),
            httpContextIntegration(),
            inboundFiltersIntegration(),
            extraErrorDataIntegration(),
            linkedErrorsIntegration(),
            reportingObserverIntegration({ types: ['crash'] }),
            // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
            thirdPartyErrorFilterIntegration({
              filterKeys: ['bexrealty'],
              behaviour: 'drop-error-if-contains-third-party-frames',
            }),
          ];

          if (import.meta.dev) {
            // Add the debug integration in dev mode
            integrations.push(debugIntegration());
          }

          return integrations;
        },
        allowUrls: [
          /bexrealty.com\/_nuxt/,
        ],
        beforeSend(event) {
          return isFilteredEvent(event) ? null : event;
        },
      });

      // Now that Sentry is initialized, remove our custom error handlers and
      // overwrite any mocked Sentry methods
      window.removeEventListener('error', enqueueError);
      window.removeEventListener('unhandledrejection', enqueuePromiseRejection);

      sentry.addBreadcrumb = addBreadcrumb;
      sentry.captureException = captureException;
      sentry.captureMessage = captureMessage;
      sentry.setExtra = setExtra;
      sentry.setUser = setUser;
      sentry.showReportDialog = showReportDialog;

      // Replay queued events
      queue?.forEach((item) => {
        if (isError(item)) {
          window.onerror?.(item.error);
        } else if (isPromiseRejection(item)) {
          window.onunhandledrejection?.(item.rejection);
        } else if (isSentryCall(item)) {
          item.call(sentry);
        }
      });

      // Setting the queue to a different type may cause HMR issues, so we only
      // set it to `null` in production builds so it can be garbage collected.
      // https://github.com/nuxt-community/sentry-module/blob/main/src/templates/plugin.lazy.js#L153
      if (import.meta.dev) {
        queue = [];
      } else {
        queue = null;
      }
    });

    return {
      provide: {
        sentry,
      },
    };
  },
});
