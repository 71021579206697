/**
 * Error class for capturing specific http status codes during SSR
 */
export class HttpError extends Error {
  constructor(message, httpStatus) {
    super(message);
    this.name = 'HttpError';
    this.httpStatus = httpStatus;

    Error.captureStackTrace(this, HttpError);
  }
}

export const httpStatusCodes = {
  BadRequest: 400,
  NotFound: 404,
  Timeout: 408,
  InternalServerError: 500
};
