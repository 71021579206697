import snakeCase from 'lodash-es/snakeCase';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import type { state } from '~/store/community-filters';

export const legacyPropertyTypes = {
  Condo: 'condominium',
  Townhouse: 'townhouse',
} as const;

export const legacyWaterfronts = {
  Deepwater: 'deep-water',
  Oceanfront: 'ocean-front',
  Yes: 'other',
} as const;

type MapParamCallback = (values: string[]) => string[];

function mapParam(value: string, callback: MapParamCallback) {
  return callback(value.split(',')).join(',');
}

export function mapLegacyPropertyTypes(values: string[]) {
  // eslint-disable-next-line security/detect-object-injection
  return values.map((value) => (legacyPropertyTypes as any)[value] ?? value);
}

export function mapLegacyWaterfronts(values: string[]) {
  // eslint-disable-next-line security/detect-object-injection
  return values.map((value) => (legacyWaterfronts as any)[value] ?? value);
}

/**
 * Converts route query params to the key/value pairs needed for API calls.
 *
 * This is used to maintain backwards compatibility with legacy frontend query
 * string params. Apparently search rankings rely heavily on these old values
 * and changing them triggers the apocalypse.
 *
 * The following alterations will be made:
 *
 * - `propertype` will be renamed `propertyType` and map its legacy values
 * - `minprice` will be renamed `minPrice`
 * - `maxprice` will be renamed `maxPrice`
 * - `waterfront` will map its legacy values
 */
export function getApiQueryParams(route: RouteLocationNormalizedLoaded) {
  const query = { ...route.query };

  if (typeof query.propertype === 'string') {
    query.propertyType = mapParam(query.propertype, mapLegacyPropertyTypes);
    delete query.propertype;
  }

  if (query.minprice) {
    query.minPrice = query.minprice;
    delete query.minprice;
  }

  if (query.maxprice) {
    query.maxPrice = query.maxprice;
    delete query.maxprice;
  }

  if (typeof query.waterfront === 'string') {
    query.waterfront = mapParam(query.waterfront, mapLegacyWaterfronts);
  }

  return query;
}

type PickFilters<T extends object> = {
  [K in keyof T as K extends `${string}Filter` ? K : never]: T[K];
}

export type CommunityFilter = keyof PickFilters<ReturnType<typeof state>>;

export function getFilterMutation(filter: CommunityFilter) {
  return `SET_${snakeCase(filter).toUpperCase()}`;
}
