import cookieUniversal from 'cookie-universal';

export default defineNuxtPlugin({
  parallel: true,
  setup() {
    const event = useRequestEvent();
    return {
      provide: {
        cookies: cookieUniversal(event?.node.req, event?.node.res),
      },
    };
  },
});
