import type { RouteLocationNormalizedLoaded } from 'vue-router';
import type { RequestTimer } from '~/services/models/request-timer';

interface AsyncDataTimerInput {
  date: string;
  headers: Readonly<Record<string, string>>;
  id: string;
  ip: string;
  label: string;
  route: RouteLocationNormalizedLoaded;
}

export class AsyncDataTimer implements RequestTimer {
  fullPath: string | null;
  hasRouteInfo: boolean;
  ip: string;
  label: string;
  now: string;
  routeName: string | null;
  #headers: Readonly<Record<string, string>>;
  #id: string;

  constructor({ date, headers, id, ip, label, route }: AsyncDataTimerInput) {
    this.ip = ip;
    this.label = label;
    this.now = date;

    if (route) {
      this.hasRouteInfo = true;
      this.fullPath = route.path;
      this.routeName = String(route.name);
    } else {
      this.hasRouteInfo = false;
      this.fullPath = null;
      this.routeName = null;
    }

    this.#headers = headers;
    this.#id = id;
  }

  get id() {
    return this.#id;
  }

  start() {
    if (import.meta.server) {
      const label = this.toString();
      console.time(label);
      console.info(`${label}: start`);
      console.info(`${label}: ${JSON.stringify(this.#headers)}`);
    }
  }

  stop() {
    if (import.meta.server) {
      console.timeEnd(this.toString());
    }
  }

  toString() {
    return `[${this.#id}] ${this.getServiceLabel()}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getServiceLabel(context?: string) {
    let timeLabel;
    if (this.hasRouteInfo) {
      timeLabel = `${this.now} [${this.ip}] ${this.routeName}-${this.label} ${this.fullPath}`;
    } else {
      timeLabel = `${this.now} [${this.ip}] ${this.label}`
    }
    return timeLabel;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getServiceBaseQuery(context: string) {
    return {
      $requestId: this.#id,
      $timeLabel: this.getServiceLabel(),
    };
  }
}
