import FeathersService from '~/services/base/feathers-service';

export class CommunityMenuService extends FeathersService {
  /**
  * Gets browse by state dropdown menu items
  */
  async getListingsByStateMenu(requestTimer) {
    return await super.request(async (baseQuery) => {
      const result = await this.service.find({
        query: {
          ...baseQuery,
          // communityId = 0 represents the 'browse by state' menu items
          communityId: 0,
          $limit: -1
        }
      });

      return result;
    }, 'getListingsByStateMenu', requestTimer, []);
  }
}
