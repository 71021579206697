export const states = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  DC: 'DC',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',

  'New-Hampshire': 'NH',
  'New-Jersey': 'NJ',
  'New-Mexico': 'NM',
  'New-York': 'NY',
  'North-Carolina': 'NC',
  'North-Dakota': 'ND',
  'Rhode-Island': 'RI',
  'South-Carolina': 'SC',
  'South-Dakota': 'SD',
  'West-Virginia': 'WV'
};


const abbreviations = Object.keys(states)
  .reduce((abbreviations, key) => {
    abbreviations[states[key]] = key;
    return abbreviations;
  }, {});

export function expand(abbreviation) {
  return abbreviations[abbreviation];
}

export default state => {
  return states[state];
};
