export const namespaced = true;

export const state = () => {
  return {
    enabled: true,
  };
};

export const mutations = {
  SET_ENABLED(state, value) {
    state.enabled = value;
  },
};

export const actions = {
  disable({ commit }) {
    commit('SET_ENABLED', false);
  },

  enable({ commit }) {
    commit('SET_ENABLED', true);
  },
};
