import FeathersService from '~/services/base/feathers-service';

const defaultAgent = 'shanej';

export class LegacyAgentService extends FeathersService {
  static defaultCcr = {
    fname: 'BEX',
    lname: 'Realty',
    phone: '800-700-9806',
  };

  async getByEmail(email, requestTimer) {
    return await super.throwableRequest(async (baseQuery) => {
      const result = await this.service.find({
        query: {
          ...baseQuery,
          email: email || defaultAgent,
        },
      });

      const data = result.data || [];
      return data[0];
    }, 'getByEmail', requestTimer);
  }
}
