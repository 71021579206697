import FeathersService from '~/services/base/feathers-service';

export class LegacyRecentPropertyService extends FeathersService {
  saveRecentProperty(property, requestTimer, $sentry) {
    return super.request(async (baseQuery) => {
      try {
        this.service.create(property, {
          query: {
            ...baseQuery,
          },
        });
      } catch (error) {
        $sentry?.setExtra('listing', property.propertyListingId);
        $sentry?.captureException(error);
      }
    }, 'saveRecentProperty', requestTimer, null, $sentry);
  }
}
