import { markRaw } from 'vue';
export const namespaced = true;

export const state = () => {
  return {
    closable: true,
    component: null,
    id: 0,
    props: null,
  };
};

export const mutations = {
  OPEN(state, payload) {
    state.closable = payload.closable ?? true;
    state.component = markRaw(payload.component);
    state.id = Date.now();
    state.props = payload.props;
  },

  RESET(state) {
    state.closable = true;
    state.component = null;
    state.id = 0;
    state.props = null;
  },
};

export const actions = {
  close({ commit }) {
    commit('RESET');
  },

  open({ commit }, payload) {
    commit('OPEN', payload);
  },

  reset({ commit }) {
    commit('RESET');
  },
};
