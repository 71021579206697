/**
 * Maps getters and setters for each key/value pair to allow two-way binding
 * from `v-model` to the store's object.
 *
 * Inspiration (copy pasta) from here: https://dev.to/matheusgomes062/how-to-make-a-form-handling-with-vuex-6g0
 *
 * @param {object} options configuration object
 * @param {string} options.module the module the object lives in
 * @param {string[]} options.fields the fields of the container to create getters and setters
 * @param {string} options.mutation the name of the mutation to call for each setter
 */
export function mapStoreFields({ module, fields, mutation }) {
  const object = {};
  for (const field of fields) {
    object[field] = {
      get() {
        return this.$store.state[module][field];
      },
      set(value) {
        this.$store.commit(`${module}/${mutation}`, { field, value });
      },
    };
  }
  return object;
}

/**
 * Function for mutating a store field.
 *
 * Primarily to be used hand-in-hand with `mapStoreFields`.
 *
 * @param {object} state the current state
 * @param {object} payload the mutation payload
 * @param {string} payload.field the name of the field to mutate
 * @param {any} payload.value the new value of the field
 */
export function mutateStoreField(state, { field, value }) {
  state[field] = value;
}
