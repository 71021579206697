import {
  adGlobalKeyNames,
  adUnitIds,
  adUnitSelectors,
  dataEventNames,
  lazyAdUnits,
  stickyFooterAdUnitIds,

  textLinkIds,
  textLinkSelectors,

  ldpPhotoCarouselAdUnitIds,
  srpCommunityAdUnitIds,

  ldpMobileAAdPixelOffsets,
  ldpPhotoCarouselAdPixelOffsets,
  srpCommunityLazyAdPixelOffsets,
} from '~/utils/cordless';

import { listingTypes } from '~/utils/listing-types';

const GMAIL_DOMAIN = 'gmail.com';

let hasFirstAdCalledBack = false;

/**
 * Gets all unique ad ids on the current page
 */
function getOnPageIds(selectors) {
  const adElements = document.querySelectorAll(selectors);

  if (adElements.length === 0) {
    return [];
  }

  const adIds = [];
  adElements.forEach((aue) => adIds.push(aue.id));

  const uniqueAdIds = [... new Set(adIds)];
  return uniqueAdIds;
}

/**
 * Splits an email into it's local part and domain (before @ sign and after)
 * @param {String} email
 */
function getEmailParts(email) {
  const splitEmail = email.split('@');

  return {
    localPart: splitEmail[0],
    domain: splitEmail[1],
  };
}

/**
 * Normalizes an email address using the following rules provided by cordless:
 *   - Remove leading and trailing spaces
 *   - Convert to lower case
 *   - If the email's domain is gmail.com:
 *     - If there is a period (.) in the address, remove it. e.g. jane.doe@gmail.com -> janedoe@gmail.com
 *     - If there is a plus (+) with an addition string after the plus, but before the '@gmail.com', remove the plus sign.
 *       e.g. janedoe+home@gmail.com -> janedoe@gmail.com
 * @param {String} email
 */
function getNormalizedEmail(email) {
  let normalizedEmail = email
    .trim()
    .toLowerCase();

  const { localPart, domain } = getEmailParts(normalizedEmail);

  if (domain === GMAIL_DOMAIN) {
    let normalizedLocalPart = localPart.replaceAll('.', '');

    const splitOnPlus = normalizedLocalPart.split('+');
    if (splitOnPlus.length > 1 && splitOnPlus[1] !== '') {
      normalizedLocalPart = splitOnPlus[0];
    }

    normalizedEmail = `${normalizedLocalPart}@${domain}`;
  }

  return normalizedEmail;
}

function getRandomLazyPixelOffset(offsetList) {
  const randomIndex = Math.floor(Math.random() * offsetList.length);
  // eslint-disable-next-line security/detect-object-injection
  const pixelOffset = offsetList[randomIndex];

  return pixelOffset;
}

/**
 * Normalizes the user's email, hashes using sha 256, and converts to hex string.
 *
 * Allows ad provider to better track who is a user is
 * @param {Object} user The logged in user
 */
async function hashUserEmail(user) {
  try {
    if (typeof user?.email !== 'string') {
      return;
    }

    const email = getNormalizedEmail(user.email);

    const msgUint8 = new TextEncoder().encode(email);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join(''); // convert bytes to hex string
    return hashHex;
  } catch {
    return;
  }
}

/**
 * Returns true if window object cmWrapper exists, and that object has a property called cmWrapper.
 * If these objects do not exist, cordless ad script was not setup successfully, and therefore we
 * prevent all interaction with cordless
 */
function isCmWrapperInitialized() {
  const cmWrapper = window?.cmWrapper;

  if(!cmWrapper || !cmWrapper.que) {
    return false;
  }

  return true;
}

function measureFirstAdCallback() {
  if (hasFirstAdCalledBack) {
    return;
  }

  performance?.mark('cordless-ads-first-ad-callback');
  const performanceMeasurement = performance?.measure('cordless-ad-script-first-ad-delay', 'default-layout-mounted', 'cordless-ads-first-ad-callback');
  console.info(`[cordless-ads]: cordless-ad-script-first-ad-delay: ${performanceMeasurement?.duration}`);
  performance?.clearMarks();
  performance?.clearMeasures();

  hasFirstAdCalledBack = true;
}

/**
 * Fetches and displays new ads for the adIds. If no ad ids provided, all units will be refreshed.
 * You should call this function every time you programmatically destroy ad ids on a page. Cordless
 * engineer says GPT (google publisher tag) does not handle dynamically removing ads well.
 * @param {String[]} adIds Ad ids to refresh
 */
function refreshAdUnits(adIds) {
  const cmWrapper = window.cmWrapper;
  const { que } = cmWrapper;

  que.push(() => {
    cmWrapper.ads.refreshUnits(adIds);
  });
}

/**
 * Requests ad ids from cordless
 * @param {Object[]} ads Ads to request
 * @param {Object} globalTargeting @see state.globalTargeting
 */
function requestAdUnits(ads, globalTargeting, commit) {
  const cmWrapper = window.cmWrapper;
  const { que } = cmWrapper;

  let globalTargettingArray;
  if (globalTargeting) {
    globalTargettingArray = [];

    for (const [key,value] of Object.entries(globalTargeting)) {
      if (value !== undefined) {
        globalTargettingArray.push([key, value]);
      }
    }
  }

  que.push(() => {
    ads.forEach((ad) => {
      cmWrapper.ads.defineUnit(ad.adId, ad.targeting);
    });

    cmWrapper.ads.requestUnits(globalTargettingArray, (data) => {
      if (!data) {
        return;
      }

      const { id, rendered } = data;
      if (!rendered) {
        return;
      }

      if (stickyFooterAdUnitIds.some((sfaui) => sfaui === id)) {
        commit('SET_IS_STICKY_FOOTER_AD_RENDERED', true);
      }

      measureFirstAdCallback();
    });
  });
}

function requestTextLinkUnits(ads, globalTargeting) {
  const cmLinkWrapper = window.cmLinkWrapper;
  const { que } = cmLinkWrapper;

  let globalTargettingArray;
  if (globalTargeting) {
    globalTargettingArray = [];

    for (const [key,value] of Object.entries(globalTargeting)) {
      if (value !== undefined) {
        globalTargettingArray.push([key, value]);
      }
    }
  }

  que.push(() => {
    ads.forEach((ad) => {
      cmLinkWrapper.links.defineLink(ad.name, ad.elementId, [["category", "mortgage"]], { placeAdCopy: false });
    });

    if (globalTargettingArray) {
      cmLinkWrapper.links.setGlobalTargeting(globalTargettingArray);
    }

    cmLinkWrapper.links.requestLinks();
  });
}

/**
 * Sends an data event to cordless
 * @param {Object} event
 */
function sendDataEvent(event) {
  const cmWrapper = window.cmWrapper;
  cmWrapper.ads.dataEvent(event);
}

/**
 * Destroys ad units for the given ids
 * @param {String[]} adIds
 */
function deleteAdUnits(adIds) {
  if (adIds.length === 0) {
    // if no ad ids are provided, then all units on the page are destroyed
    // we likely never want this to happen, therefore prevent it
    return;
  }

  const cmWrapper = window.cmWrapper;
  const { que } = cmWrapper;

  que.push(() => {
    cmWrapper.ads.destroyUnits(adIds);
  });
}

export const state = () => ({
  /** stores the ad ids that are currently registered with cordless */
  adIds: [],
  /**
   * Stores the ad ids that are currently queued to be requested.
   * Queued ad ids were requested, before `cordless-ad-script.vue` was initialized
   */
  adIdQueue: [],
  /** An object that contains contextual information about the page. Cordless uses this to better target ads to a user */
  globalTargeting: null,
  isInitialized: false,
  /**
   * If true, a sticky footer was registered, and the ad has rendered. Used in
   * `cordless-sticky-footer.vue` to place a css class, and attach a click event listener to this ad.
   * */
  isStickyFooterAdRendered: false,

  /** TEXT LINKS */
  isTextLinkInitialized: false,
  /** Stores the text link ad ids that are currently queued to be requested
   * Queued text link ids were requested before `cordless-text-links-script.vue` was initialized
   */
  textLinkIdQueue: [],
  /** An object that contains contextual information about the page. ONLY use for text links */
  textLinkGlobalTargeting: null,

  /** LAZY OPTIMIZATION */
  isLazyPixelOffsetInitialized: false,
  ldpMobileAdALazyPixelOffset: null,
  ldpPhotoCarouselLazyPixelOffset: null,
  srpCommunityLazyPixelOffset: null,
});

export const getters = {
  getLazyPixelOffsetByAdId: (state) => (adId) => {
    let lazyPixelOffset;

    if (adId === adUnitIds.ldpMobileA) {
      lazyPixelOffset = state.ldpMobileAdALazyPixelOffset;
    } else if (ldpPhotoCarouselAdUnitIds.includes(adId)) {
      lazyPixelOffset = state.ldpPhotoCarouselLazyPixelOffset;
    } else if (srpCommunityAdUnitIds.includes(adId)) {
      lazyPixelOffset = state.srpCommunityLazyPixelOffset;
    }

    return lazyPixelOffset;
  },
}

export const mutations = {
  ADD_AD_ID(state, value) {
    if (state.adIds.includes(value)) {
      return;
    }

    state.adIds.push(value);
  },
  CLEAR_AD_ID_QUEUE(state) {
    state.adIdQueue = [];
  },
  CLEAR_TEXT_LINK_ID_QUEUE(state) {
    state.textLinkIdQueue = [];
  },
  SET_AD_IDS(state, values) {
    state.adIds = values;
  },
  SET_GLOBAL_TARGETING(state, value) {
    state.globalTargeting = value;
  },
  SET_IS_INITIALIZED(state, value) {
    state.isInitialized = value && isCmWrapperInitialized();
  },
  SET_IS_STICKY_FOOTER_AD_RENDERED(state, value) {
    state.isStickyFooterAdRendered = value;
  },
  SET_TEXT_LINK_GLOBAL_TARGETING(state, value) {
    state.textLinkGlobalTargeting = value;
  },
  SET_TEXT_LINK_INITIALIZED(state, value) {
    state.isTextLinkInitialized = value;
  },
  QUEUE_AD_ID(state, value) {
    if (state.adIdQueue.includes(value)) {
      return;
    }

    state.adIdQueue.push(value);
  },
  QUEUE_TEXT_LINK_ID(state, value) {
    if (state.textLinkIdQueue.includes(value)) {
      return;
    }

    state.textLinkIdQueue.push(value);
  },

  /** LAZY OPTIMIZATION */
  SET_IS_LAZY_PIXEL_OFFSET_INITIALIZED(state, value) {
    state.isLazyPixelOffsetInitialized = value;
  },
  SET_LDP_MOBILE_AD_A_LAZY_PIXEL_OFFSET(state, value) {
    state.ldpMobileAdALazyPixelOffset = value;
  },
  SET_LDP_PHOTO_CAROUSEL_LAZY_PIXEL_OFFSET(state, value) {
    state.ldpPhotoCarouselLazyPixelOffset = value;
  },
  SET_SRP_COMMUNITY_LAZY_PIXEL_OFFSET(state, value) {
    state.srpCommunityLazyPixelOffset = value;
  },
}

export const actions = {
  getAdIdsWithTargeting({getters}, adIds) {
    const adIdsWithTargeting = adIds.map((id) => {
      const result = {
        adId: id,
        targeting: undefined,
      }

      const lazyPixelOffset = getters.getLazyPixelOffsetByAdId(id);
      if (!lazyPixelOffset?.key) {
        return result;
      }

      result.targeting = [[adGlobalKeyNames.optimization, lazyPixelOffset.key]];
      return result;
    });

    return adIdsWithTargeting;
  },
  /**
   * Initializes all ads on the page, requests that all slots be filled with ads
   * @param {Object} context
   * @param {Object} options
   * @param {Object} options.globalTargeting
   */
  async initialize({ commit, dispatch, state }, options = {}) {
    const globalTargeting = options.globalTargeting;
    const user = options.user ?? {};

    const adIds = getOnPageIds(adUnitSelectors);
    // remove any lazy ad ids, they will lazy load themselves when they come into viewport
    const greedyAdIds = adIds.filter((ai) => !lazyAdUnits.includes(ai));
    const queuedAdIds = state.adIdQueue;

    const allAdIds = [ ...greedyAdIds, ...queuedAdIds ];

    commit('SET_AD_IDS', allAdIds);

    const hashedEmail = await hashUserEmail(user);

    const mergedGlobalTargeting = {
      [adGlobalKeyNames.hashedEmail]: hashedEmail,
      ...globalTargeting,
    }

    const adIdsWithTargeting = await dispatch('getAdIdsWithTargeting', allAdIds);
    requestAdUnits(adIdsWithTargeting, mergedGlobalTargeting, commit);

    if (process.env.logDebug) {
      console.log('[cordless-ads] initialize -> adIds', allAdIds.length, allAdIds);
      console.log('[cordless-ads] initialize -> globalTargeting', JSON.stringify(mergedGlobalTargeting));
    }

    commit('CLEAR_AD_ID_QUEUE');
    commit('SET_GLOBAL_TARGETING', mergedGlobalTargeting);
    commit('SET_IS_INITIALIZED', true);

    performance?.mark('cordless-ads-initialized');
    const performanceMeasurement = performance?.measure('cordless-ad-script-initialized-delay', 'default-layout-mounted', 'cordless-ads-initialized');
    console.info(`[cordless-ads]: cordless-ad-script-initialized-delay: ${performanceMeasurement?.duration}`);
  },
  /**
   * Generates a randomly selected pixel offset for all lazy loaded ads on a given page load.
   * Should run once per page load
   * @param {Object} context
   */
  initializeLazyPixelOffsets({ commit, state }) {
    if (state.isLazyPixelOffsetInitialized) {
      return;
    }

    const ldpMobileAAdPixelOffset = getRandomLazyPixelOffset(ldpMobileAAdPixelOffsets);
    const ldpPhotoCarouselOffset = getRandomLazyPixelOffset(ldpPhotoCarouselAdPixelOffsets);
    const srpCommunityOffset = getRandomLazyPixelOffset(srpCommunityLazyAdPixelOffsets);

    commit ('SET_LDP_MOBILE_AD_A_LAZY_PIXEL_OFFSET', ldpMobileAAdPixelOffset);
    commit('SET_LDP_PHOTO_CAROUSEL_LAZY_PIXEL_OFFSET', ldpPhotoCarouselOffset);
    commit('SET_SRP_COMMUNITY_LAZY_PIXEL_OFFSET', srpCommunityOffset);

    commit('SET_IS_LAZY_PIXEL_OFFSET_INITIALIZED', true)

    if (process.env.logDebug) {
      console.log('[cordless-ads] lazyPixelOffsets -> ldpMobileAAdPixelOffset', JSON.stringify(state.ldpMobileAdALazyPixelOffset));
      console.log('[cordless-ads] lazyPixelOffsets -> ldpPhotoCarouselLazyPixelOffset', JSON.stringify(state.ldpPhotoCarouselLazyPixelOffset));
      console.log('[cordless-ads] lazyPixelOffsets -> srpCommunityLazyPixelOffset', JSON.stringify(state.srpCommunityLazyPixelOffset));
    }
  },
  async initializeTextLinks({ commit, state }, options = {}) {
    const globalTargeting = options.globalTargeting;

    const adIds = getOnPageIds(textLinkSelectors);
    const queuedAdIds = state.textLinkIdQueue;

    const allAdIds = [ ...adIds, ...queuedAdIds ];

    const ads = allAdIds
      .map((id) => Object.values(textLinkIds).find((tli) => tli.elementId === id));

    if (ads.length === 0) {
      return;
    }

    requestTextLinkUnits(ads, globalTargeting);

    if (process.env.logDebug) {
      console.log('[cordless-ads] initializeTextLinks -> adIds', allAdIds.length, ads);
      console.log('[cordless-ads] initializeTextLinks -> globalTargeting', JSON.stringify(globalTargeting));
    }

    commit('CLEAR_TEXT_LINK_ID_QUEUE');
    commit('SET_TEXT_LINK_GLOBAL_TARGETING', globalTargeting);
    commit('SET_TEXT_LINK_INITIALIZED', true);
  },
  /**
   * Requests a single ad by id
   * @param {Object} context
   * @param {String} adId
   */
  async requestAd({ commit, dispatch, state }, adId) {
    if (!state.isInitialized) {
      if (process.env.logDebug) {
        console.log('[cordless-ads] ad is now queued', adId);
      }
      commit('QUEUE_AD_ID', adId);
      return;
    }

    commit('ADD_AD_ID', adId);

    if (process.env.logDebug) {
      console.log('[cordless-ads] requestAd -> newAdId', adId);
    }

    const adIdsWithTargeting = await dispatch('getAdIdsWithTargeting', [adId]);
    requestAdUnits(adIdsWithTargeting, state.globalTargeting, commit);
  },
  /**
   * For all ad units on page, requests new ad units, deletes removed ad units
   * @param {Object} context
   */
  async requestAdChanges({ commit, dispatch, state }) {
    if (!state.isInitialized) {
      return;
    }

    const { adIds: oldAdIds } = state;

    const onPageAdUnitIds = getOnPageIds(adUnitSelectors);
    commit('SET_AD_IDS', onPageAdUnitIds);

    const newAdIds = onPageAdUnitIds.filter((id) => !oldAdIds.includes(id));
    const deletedAdIds = oldAdIds.filter((id) => !onPageAdUnitIds.includes(id));

    if (newAdIds.length) {
      if (process.env.logDebug) {
        console.log('[cordless-ads] requestAdChanges -> newAdIds', newAdIds.length, newAdIds);
      }
      const adIdsWithTargeting = await dispatch('getAdIdsWithTargeting', newAdIds);
      requestAdUnits(adIdsWithTargeting, state.globalTargeting, commit);
    }

    if (deletedAdIds.length) {
      if (process.env.logDebug) {
        console.log('[cordless-ads] requestAdChanges -> deletedAdIds', deletedAdIds.length, deletedAdIds);
        console.log('[cordless-ads] requestAdChanges -> onPageAdUnitIds', onPageAdUnitIds.length, onPageAdUnitIds);
      }

      deleteAdUnits(deletedAdIds);
    }

    if ((newAdIds.length || deletedAdIds.length) && onPageAdUnitIds.length) {
      if (process.env.logDebug) {
        console.log('[cordless-ads] requestAdChanges -> onPageAdUnitIds', onPageAdUnitIds.length, onPageAdUnitIds);
      }
      refreshAdUnits(onPageAdUnitIds);
    }
  },
  requestTextAd({ commit, state }, adId) {
    if (!state.isTextLinkInitialized) {
      if (process.env.logDebug) {
        console.log('[cordless-ads] text ad is now queued', adId);
      }

      commit('QUEUE_TEXT_LINK_ID', adId)
      return;
    }


    const ad = Object.values(textLinkIds).find((tli) => tli.elementId === adId);
    if (!ad) {
      return;
    }

    requestTextLinkUnits([ad], state.textLinkGlobalTargeting);

    if (process.env.logDebug) {
      console.log('[cordless-ads] requestTextAd -> newAd', ad);
    }
  },
  /**
   * On inquiry submit, send the email the lead inputted to cordless
   * @param {Object} context
   * @param {Object} data
   * @param {String} listingTypeId
   */
  async submitInquiryEmail({ state }, data) {
    try {
      if (!state.isInitialized) {
        return;
      }

      const { email, listingTypeId } = data;

      const hashedEmail = await hashUserEmail({ email });

      const event = {
        hashedEmail,
        name: dataEventNames.default,
      };

      if (listingTypeId === listingTypes.sale) {
        event.name = dataEventNames.inquiryBuyPage;
      } else if (listingTypeId === listingTypes.rent) {
        event.name = dataEventNames.inquiryRentPage;
      }

      sendDataEvent(event);
    } catch(error) {
      console.error(error);
    }
  }
}

export const namespaced = true;
