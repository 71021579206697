export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    if (import.meta.server) {
      const headers = useRequestHeaders();
      nuxtApp.payload.requestIp = headers['x-forwarded-for']?.split(',')[0].trim() ?? '';
      nuxtApp.payload.requestUserAgent = headers['x-forwarded-user-agent'] ?? headers['user-agent'] ?? '';
    }

    return {
      provide: {
        requestIp: nuxtApp.payload.requestIp,
        requestUserAgent: nuxtApp.payload.requestUserAgent,
      },
    };
  },
});
