/**
 * Converts viewport query parameter into an object to be used to query the api
 * @param {String} encoded
 */
export function decodeViewport(encodedViewport) {
  const defaultViewport = null;

  if (encodedViewport === null) {
    return defaultViewport;
  }

  const latLngSplit = encodedViewport.split(':');
  if (latLngSplit.length !== 2) {
    return defaultViewport;
  }

  const sw = latLngSplit[0].split(',');
  const ne = latLngSplit[1].split(',');

  if (sw.length !== 2 && ne.length !== 2) {
    return defaultViewport;
  }

  const swLat = Number(sw[0]);
  const swLng = Number(sw[1]);
  const neLat = Number(ne[0]);
  const neLng = Number(ne[1]);

  if (!swLat || !swLng || !neLat || !neLng) {
    return defaultViewport;
  }

  const viewport = { swLat, swLng, neLat, neLng };

  return viewport;
}

/**
 * Converts google map bounds object into a url friendly query parameter
 * @param {Object} bounds
 */
export function encodeViewport(bounds) {
  const swLat = bounds.getSouthWest().lat();
  const swLng = bounds.getSouthWest().lng();
  const neLat = bounds.getNorthEast().lat();
  const neLng = bounds.getNorthEast().lng();

  const viewport = `${swLat},${swLng}:${neLat},${neLng}`;
  return viewport;
}
