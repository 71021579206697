export const communityHouseTypeMap = {
  apartment: 'Apartments',
  'single-family-home': 'Single-Family Homes',
  'multi-family-home': 'Multi-Family Homes',
  Condo: 'Condos',
  condominium: 'Condos',
  Townhouse: 'Townhouses',
  townhouse: 'Townhouses',
  villa: 'Villas',
  houseboat: 'Houseboats',
  home: 'Homes',
  land: 'Land Properties',
  agricultural: 'Agricultural Properties',
  'boat-slip': 'Boat Slips',
  'deeded-parking': 'Deeded Parking Listings'
};

export const filterPropertyTypeMap = {
  apartment: 'Apartment',
  'single-family-home': 'Single-family home',
  'multi-family-home': 'Multi-family home',
  condominium: 'Condominium',
  villa: 'Villa',
  townhouse: 'Townhouse',
  houseboat: 'Houseboat',
  home: 'Home',
  land: 'Land',
  agricultural: 'Agricultural Property',
  'boat-slip': 'Boat Slip',
  'deeded-parking': 'Deeded Parking'
};

export const structureMap = {
  'single-story': 'Single-story',
  'multi-story': 'Multi-story'
};

export const waterfrontMap = {
  'all': 'All Waterfront',
  'bay': 'Bay',
  'basin': 'Basin',
  'canal': 'Canal',
  'deep-water': 'Deep Water',
  'gulf': 'Gulf',
  'gulf-access': 'Gulf Access',
  'intracoastal': 'Intracoastal',
  'lake': 'Lake',
  'marina': 'Marina',
  'no-fixed-bridges': 'No Fixed Bridges',
  'ocean-access': 'Ocean Access',
  'ocean-front': 'Oceanfront',
  'open-water': 'Open Water',
  'other': 'Waterfront',
};

export const houseTypeMap = {
  apartment: 'Apartments',
  'single-family-home': 'Single-Family Homes',
  'multi-family-home': 'Multi-Family Homes',
  Condo: 'Condos',
  condominium: 'Condos',
  Townhouse: 'Townhouses',
  townhouse: 'Townhouses',
  villa: 'Villas',
  houseboat: 'Houseboats',
  home: 'Homes',
  land: 'Land',
  agricultural: 'Agricultural Properties',
  'boat-slip': 'Boat Slips',
  'deeded-parking': 'Deeded Parking'
};

export const rentalHouseTypeMap = {
  apartment: 'Rental Apartments',
  'single-family-home': 'Single-Family Rental Homes',
  'multi-family-home': 'Multi-Family Rental Homes',
  Condo: 'Rental Condos',
  condominium: 'Rental Condos',
  Townhouse: 'Rental Townhouses',
  townhouse: 'Rental Townhouses',
  villa: 'Rental Villas',
  houseboat: 'Rental Houseboats',
  home: 'Rental Homes',
  land: 'Rental Land',
  agricultural: 'Rental Agricultural Properties',
  'boat-slip': 'Rental Boat Slips',
  'deeded-parking': 'Rental Deeded Parking'
};

export const listingCardHouseTypeMap = {
  apartment: 'Apartment',
  'single-family-home': 'Single-Family Home',
  'multi-family-home': 'Multi-Family Home',
  Condo: 'Condo',
  condominium: 'Condo',
  Townhouse: 'Townhouse',
  townhouse: 'Townhouse',
  Villa: 'Villa',
  villa: 'Villa',
  Houseboat: 'Houseboat',
  houseboat: 'Houseboat',
  home: 'Home',
  land: 'Land',
  agricultural: 'Agricultural Property',
  'boat-slip': 'Boat Slip',
  'deeded-parking': 'Deeded Parking'
};

export const listingMetaHouseTypeMap = {
  apartment: 'apartment',
  'single-family-home': 'single-family home',
  'multi-family-home': 'multi-family home',
  Condo: 'condo',
  condominium: 'condo',
  Townhouse: 'townhouse',
  townhouse: 'townhouse',
  Villa: 'villa',
  villa: 'villa',
  Houseboat: 'houseboat',
  houseboat: 'houseboat',
  home: 'home',
  land: 'land',
  agricultural: 'agricultural property',
  'boat-slip': 'boat slip',
  'deeded-parking': 'deeded parking'
};

export const singleLowercaseHouseTypeMap = {
  apartment: 'apartment',
  'single-family-home': 'single-family home',
  'multi-family-home': 'multi-family home',
  condominium: 'condo',
  villa: 'villa',
  townhouse: 'townhouse',
  houseboat: 'houseboat',
  home: 'home',
  land: 'land',
  agricultural: 'agricultural property',
  'boat-slip': 'boat slip',
  'deeded-parking': 'deeded parking',
  // Below used for anchor title text on listing cards
  Apartments: 'Apartment',
  'Single-Family Homes': 'Single-Family Home',
  'Multi-Family Homes': 'Multi-Family Home',
  Condos: 'Condo',
  Townhouses: 'Townhouse',
  Villas: 'Villa',
  Houseboats: 'Houseboat',
  Homes: 'Home',
  Land: 'Land',
  'Agricultural Properties': 'Agricultural Property',
  'Boat Slips': 'Boat Slip',
  'Deeded Parking': 'Deeded Parking'
};

export const listingFooterHouseTypeMap = {
  apartment: 'apartments',
  'single-family-home': 'single-family homes',
  'multi-family-home': 'multi-family homes',
  condominium: 'condos',
  villa: 'villas',
  townhouse: 'townhomes', //townhomes vs. Townhouses
  houseboat: 'houseboats',
  home: 'homes',
  land: 'land',
  agricultural: 'agricultural properties',
  'boat-slip': 'boat slips',
  'deeded-parking': 'deeded parking'
};

// Used on listing pages in features section
export const listingDescriptiveTypeMap = {
  apartment: 'Apartment',
  'single-family-home': 'Single-family home',
  'multi-family-home': 'Multi-family home',
  condominium: 'Condominium',
  villa: 'Villa',
  townhouse: 'Townhouse',
  houseboat: 'Houseboat',
  home: '',
  land: 'Land',
  agricultural: 'Agricultural property',
  'boat-slip': 'Boat Slip',
  'deeded-parking': 'Deeded Parking'
};

export const financeTextMap = {
  'auction': 'Auction',
  'bank-owned': 'Bank Owned',
  'foreclosure': 'Foreclosure',
  'short-sale': 'Short Sale',
};

export const listingTypeMap = {
  rent: 'For Rent',
  sale: 'For Sale',
  contingency: 'Under Contract',
  pending: 'Under Contract',
  'coming-soon': 'Coming Soon',
  sold: ''
};

export const listingTypeFeaturesMap = {
  active: 'Active',
  rent: 'For Rent',
  sale: 'For Sale',
  contingency: 'Under Contract',
  pending: 'Under Contract',
  'coming-soon': 'Coming Soon',
  sold: 'Sold',
  'offline-only': 'Offline'
};

export const poolTextMap = {
  'community-pool': 'Community Pool',
  'private-pool': 'Private Pool',
  'community-spa': 'Community Spa',
  'private-spa': 'Private Spa',
};

// Listing History Maps
export const historyListingTypes = {
  active: 'Active',
  rent: 'For Rent',
  sale: 'For Sale',
  contingency: 'Under Contract (Contingent)',
  pending: 'Under Contract (Pending)',
  'coming-soon': 'Coming Soon',
  sold: 'Sold',
  'offline-only': 'Off the market'
};
