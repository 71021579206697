/**
 * List of named cookies
 */
export const cookieNames = {
  agent: 'agent',
  feathersJwt: 'feathers-jwt',
  googleAdwordGclid: 'g-adword-gclid',
  location: 'location',
  referrer: 'referrer',
  referSource: 'refersource',
  searchMode: 'search-mode',
};

/**
 * Gets an expiration date of now + seconds
 * @param {Number} seconds
 */
export function getExpirationDateBySeconds(seconds) {
  const expirationDate = new Date();
  expirationDate.setSeconds(expirationDate.getSeconds() + seconds);

  return expirationDate;
}
