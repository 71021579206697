import { useCommunityMenuService } from '~/composables/use-community-menu-service';
import { RequestTimer } from '~/services/models/request-timer';

// Global state
export const state = () => ({
  location: {},
  menus: [],
});

// Mutations
// Updates the state, only synchronous methods here
export const mutations = {
  SET_MENUS(state, menus) {
    state.menus = menus;
  },
}

// Getters
export const getters = {
  listingsByStateMenu(state) {
    return state.menus.find((menu) => menu.name === 'Listings By State')?.links ?? [];
  },
}

// Actions
// Can be async to fetch data and then set the state by running mutations
export const actions = {
  /**
   * @function fetchMenus
   * Load the regional menus for the URL given
   * @param {Object} store The Nuxt store context
   */
  async fetchMenus ({ commit }) {
    if (process.env.isMaintenance) {
      return;
    }

    const requestTimer = new RequestTimer('fetchMenus');
    const menus = await useCommunityMenuService().getListingsByStateMenu(requestTimer);
    commit('SET_MENUS', menus);
  },

  /**
   * @function nuxtServerInit
   * Runs when SSR is being run, so only runs on server for initial request
   * @param {Object} store
   * @param {Object} context
   */
  async nuxtServerInit ({ dispatch }) {
    try {
      await Promise.all([
        // Dispatch the above action to populate the store with the menus
        dispatch('fetchMenus'),
        // Generate ad lazy pixel offsets
        dispatch('cordless-ads/initializeLazyPixelOffsets'),
      ]);
    } catch (e) {
      console.error(`store.nuxtServerInit() [ERROR]: error: ${e.message}`);
      throw e;
    }
  }
}
