/** bex api error codes */
export const apiErrorTypes = {
  /** base bexrealty error */
  BexrealtyError: 'bexrealty-error',


  /** http errors */
  /** use http errors to invoke specific browser behavior. e.g. 404, 301, etc. */

  /** front end must report an http 404 to client */
  NotFoundError: 'not-found-error',

  /** front end must redirect. optionally use data.redirectTo for redirect url */
  RedirectError: 'redirect-error',

  /** ui errors */
  /** use ui errors to show error messaging to users */

  /** base bexrealty ui error */
  BexrealtyUiError: 'bexrealty-ui-error',

  CampaignSurveyMessageNotFoundError: 'campaign-survey-message-not-found-error',

  /** captcha validation failed */
  CaptchaValidationUiError: 'captcha-validation-ui-error',

  /** user was not found */
  UserNotFoundUiError: 'user-not-found-ui-error',
};
