/* This is used to correct times when looking at
   $route.params.state to gather state names.
   Example: In Regional Nav "All North-Carolina Cities"
   Becomes: "All North Carolina Cities"
*/

export const stateNameMap = {
  'New-Hampshire': 'New Hampshire',
  'New-Jersey': 'New Jersey',
  'New-Mexico': 'New Mexico',
  'New-York': 'New York',
  'North-Carolina': 'North Carolina',
  'North-Dakota': 'North Dakota',
  'Rhode-Island': 'Rhode Island',
  'South-Carolina': 'South Carolina',
  'South-Dakota': 'South Dakota',
  'West-Virginia': 'West Virginia'
};
