import VueGoogleMaps from 'vue-google-maps-community-fork';

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.vueApp.use(VueGoogleMaps, {
      autobindAllEvents: true,
      installComponents: false,
      load: {
        key: process.env.gmapsKey,
      },
    });
  },
});
