import { useCookieUniversal } from '~/composables/use-cookie-universal';
import { useFeathers } from '~/composables/use-feathers';
import { useLegacyAgentService } from '~/composables/use-legacy-agent-service';
import { useLegacyLoginLocationService } from '~/composables/use-legacy-login-location-service';
import { useLegacyRecentPropertyService } from '~/composables/use-legacy-recent-property-service';
import { useLegacySavedPropertyService } from '~/composables/use-legacy-saved-property-service';
import { useRecentCommunityService } from '~/composables/use-recent-community-service';
import { useUserService } from '~/composables/use-user-service';
import { cookieNames, getExpirationDateBySeconds } from '~/utils/cookies';
import { RequestTimer } from '~/services/models/request-timer';
import { localStorageProxy } from '~/utils/local-storage';

const CompleteRegistrationForm = defineAsyncComponent(() => import('~/components/complete-registration-form.vue'));

const legacyCookieNames = ['PHPSESSID', 'cookname', 'cookid', 'onetap'];

const RECENT_COMMUNITY_INDEX = 'recent_community_index';
const RECENT_PROPERTY_INDEX = 'recent_property_index';

export const namespaced = true;


export const state = () => ({
  assignedAgent: {},
  pending: true,
  savedListings: [],
  sharedAgent: {},
  user: null,
  userId: null,
});

export const getters = {
  isLoggedIn (state) {
    const user = state.user || {};
    return !!user.id;
  },
  isBexLoggedIn (state) {
    const user = state.user || {};
    return user.userlevel === 9;
  },
  user (state) {
    return state.user;
  },
  assignedAgent (state) {
    return state.assignedAgent;
  },
  sharedAgent (state) {
    return state.sharedAgent;
  },
  savedListings (state) {
    return state.savedListings;
  },
}

export const mutations = {
  SET_PENDING(state, pending) {
    state.pending = pending;
  },

  setUser (state, user) {
    state.user = user;
  },
  setUserId (state, userId) {
    state.userId = userId;
  },
  addSavedListing (state, listingId) {
    state.savedListings.push(Number(listingId));
  },
  removeSavedListing (state, listingId) {
    const listingIdNumber = Number(listingId);
    state.savedListings = state.savedListings.filter(id => id !== listingIdNumber);
  },
  setAssignedAgent (state, agent) {
    state.assignedAgent = agent;
  },
  SET_SHARED_AGENT (state, agent) {
    state.sharedAgent = agent ?? {};
  },
  unsetUserData (state) {
    state.assignedAgent = {};
    state.savedListings = [];
    state.sharedAgent = {};
    state.user = {};
    state.userId = null;
  },
};

export const actions = {
  /**
   * @function authenticate
   * Authenticate user
   * @param {Object} store The Nuxt store context
   * @param {Object} credentials
   */
  async authenticate ({ commit, dispatch }, credentials) {
    try {
      commit('SET_PENDING', true);
      dispatch('loading/start', null, { root: true });

      const feathers = useFeathers();
      const result = await feathers.authenticate(credentials);
      const { legacyCookies } = result;

      if (legacyCookies) {
        const $cookies = useCookieUniversal();
        legacyCookies.forEach(cookie => {
          if (cookie.options.expires) {
            cookie.options.expires = new Date(cookie.options.expires);
          }

          cookie.options.sameSite = 'Lax';

          $cookies.set(cookie.name, cookie.value, cookie.options);
        });
      }

      // If the authentication service doesn't return a user for some reason,
      // capture the error in Sentry since we can't continue authenticating
      // without it.
      try {
        if (typeof result.user !== 'object' || result.user === null) {
          throw new Error(`store.authenticate(): payload did not return user. username ${credentials?.username}`);
        }
      } catch (error) {
        this.$sentry?.captureException(error);
        // login form prints whatever error message is thrown, so give a more friendly message
        throw new Error('Could not authenticate');
      }

      // Add the user context once they login
      // During CI we disable sentry so we need to check that it's available
      this.$sentry?.setUser({ id: result.user.username });

      // Set the userId state
      commit('setUserId', result.user.id);

      // Load the user
      commit('setUser', result.user);

      // Load users's ancillary information
      dispatch('loadUserDetails');

      // Save any stored recent properties and communities
      dispatch('saveStoredRecentProperties');
      dispatch('saveStoredRecentCommunities');

      // Save login locations
      dispatch('saveLoginLocation');
    } catch (error) {
      // Set user state so we have empty object for non-logged in user
      commit('setUser', {});
      dispatch('clearUserState');

      // 401 = unauthorized
      // if code is not unauthorized something unexpected went wrong
      if (error.code !== 401) {
        this.$sentry?.captureException(error);
      }

      throw error;
    } finally {
      dispatch('loading/finish', null, { root: true });
      commit('SET_PENDING', false);
    }
  },

  async reAuthenticate({ commit, dispatch }) {
    const feathers = useFeathers();

    try {
      commit('SET_PENDING', true);
      await feathers.reAuthenticate();
      const { user } = await feathers.get('authentication');
      commit('setUserId', user.username);
      commit('setUser', user);
      this.$sentry?.setUser({ id: user.username });

      dispatch('loadUserDetails');
      dispatch('saveStoredRecentProperties');
      dispatch('saveStoredRecentCommunities');
      dispatch('saveLoginLocation');
    } catch {
      // ignore error
    } finally {
      commit('SET_PENDING', false);
    }
  },

  async logout ({ dispatch }) {
    try {
      const feathers = useFeathers();
      await feathers.logout();
      await dispatch('clearUserState');
    } catch (error) {
      console.error(error);
    }

    try {
      useNuxtApp().$googleAuth.signOut();
    } catch (error) {
      console.error(error);
    }
  },

  async fetchUser({ commit }, userId) {
    const feathers = useFeathers();
    const service = useUserService();
    const user = await service.getUser(userId, new RequestTimer('store-user'));
    feathers.set('user', user);
    commit('setUser', user);
  },

  async clearUserState ({commit}) {
    try {
      const $cookies = useCookieUniversal();
      const feathers = useFeathers();
      legacyCookieNames.forEach(cookie => $cookies.remove(cookie));
      $cookies.remove(cookieNames.location);
      feathers.set('user', {});
      feathers.set('legacyCookies', []);
      if (process.client) {
        // Remove the complete-registration storage item
        localStorageProxy.removeItem('complete-registration-dismissed');
      }
      commit('unsetUserData');
    } catch (error) {
      console.error(error);
    }
  },
  async loadUserDetails ({ dispatch, state }) {
    try {
      // Fetch the user
      const user = state.user;

      // Show the complete-registration modal
      // *if* we need to
      dispatch('showPhoneEmailDialogue', user);

      // Load user favorites
      dispatch('getFavorites');

      // Get Assigned Agent
      dispatch('getAssignedAgent');
      dispatch('getSharedAgent');
    } catch (error) {
      this.$sentry?.captureException(error);
    }
  },
  async getFavorites ({ commit, state }) {
    try {
      const user = state.user || {};
      const service = useLegacySavedPropertyService();
      const data = await service.getSavedProperties(user.id, new RequestTimer('store-user'), this.$sentry);

      data.map(listing => {
        commit('addSavedListing', listing.propertyListingId);
      });
    } catch (error) {
      console.error(error);
    }
  },
  async makeFavorite ({ commit, state }, propertyListingId) {
    try {
      const data = {
        username: state.user.id,
        propertyListingId,
      };

      const service = useLegacySavedPropertyService();
      await service.create(data, new RequestTimer('store-user'), this.$sentry);

      commit('addSavedListing', propertyListingId);
    } catch (error) {
      console.error(error);
    }
  },
  async removeFavorite({ commit, state }, propertyListingId) {
    try {
      const data = {
        username: state.user.id,
        propertyListingId
      };

      const service = useLegacySavedPropertyService();
      await service.remove(data, new RequestTimer('store-user'), this.$sentry);

      commit('removeSavedListing', propertyListingId);
    } catch (error) {
      console.error(error);
    }
  },
  async getAssignedAgent ({ commit, state }) {
    try {
      const service = useLegacyAgentService();
      const response = await service.getByEmail(state.user.assigned, new RequestTimer('store-user'));
      commit('setAssignedAgent', response);
    } catch (error) {
      console.error(`Failed to load agent: ${error.message}`);
    }
  },
  async getSharedAgent ({ commit, state }) {
    const sharedAgent = state.user.shared_agent;

    if (sharedAgent) {
      try {
        const service = useLegacyAgentService();
        const response = await service.getByEmail(sharedAgent, new RequestTimer('store-user'));
        commit('SET_SHARED_AGENT', response);
      } catch (error) {
        console.error(`Failed to load shared agent: ${error.message}`);
      }
    }
  },
  /**
   * @function showPhoneEmailDialogue
   * @param {Object} context Vuex context
   * @param {Object} user The user object
   * Check if the user is required to complete the phone / email data in the dialogue
   * We will add a timestamp so we know when to check it next
   */
  async showPhoneEmailDialogue ({ dispatch }, user) {
    if (process.server) {
      return;
    }

    const now = Date.now();
    const dismissedTime = parseInt(localStorageProxy.getItem('complete-registration-dismissed'));
    const showDialogue = !dismissedTime || dismissedTime <= now;

    /**
     * If we have a user for login / after register
     * ie. it's not a login with jwt
     *
     * Also, only show dialogue if user.isPhoneValid is null. We don't want to show dialogue
     * if a user has given a phone number but it was removed bc it was invalid.
     */
    if (showDialogue && ((!user.phone && user.isPhoneValid === null) || !user.email)) {
      dispatch('modal/open', { component: CompleteRegistrationForm, closable: false }, {
        root: true,
      });
    }
  },

  /**
   * Checks location cookie and creates a login location if the ip address has changed.
   * Cookie is created and checked for change in login-location-cookie.js middleware
   * @param {Object} param0 vuex context
   */
  async saveLoginLocation({ state }) {
    const $cookies = useCookieUniversal();
    const cookie = $cookies.get(cookieNames.location);

    if (!cookie) {
      return
    }

    if (cookie.ip && cookie.changed) {
      const service = useLegacyLoginLocationService();
      await service.createLoginLocation(state.userId, cookie.ip, new RequestTimer('store-user'), this.$sentry);

      // set cookie's changed value to false to prevent creating login location on every page refresh
      const oneHourFromNow = getExpirationDateBySeconds(3600);
      $cookies.set(cookieNames.location, {
        ip: cookie.ip,
        changed: false,
      }, {
        expires: oneHourFromNow,
        path: '/',
        sameSite: 'Lax',
      });
    }
  },

  /**
   * Once a user logs in, which is either a pure login, or after registration, this will pull out
   * all recent properties that have been saved within localStorage. It will sort by the most
   * recent data first, group them into chunks of 5, upload each chunk, and finally remove them
   * from localStorage.
   * We prioritize the most recent data because if there is any failure/rate limit when sending
   * chunks of data, we want to ensure the freshest, most valuable, data is stored for the lead.
   * @param {Object} context Vuex context
   */
  async saveStoredRecentProperties ({ dispatch, state }) {
    if (process.server) {
      return;
    }

    const recentPropertyKeys = JSON.parse(localStorageProxy.getItem(RECENT_PROPERTY_INDEX)) ?? [];
    // keys are stored by date ascending, so reverse to sort by date descending
    recentPropertyKeys.reverse();

    if (!recentPropertyKeys.length) {
      return;
    }

    const KEY_SYMBOL = Symbol.for('key_symbol');

    // Split into chunks of up to 5 RPVs
    // [[{proper1}, {proper2}], [{proper3}]]
    const chunkedRecentProperties = recentPropertyKeys.reduce((acc, key, index) => {
      const chunkIndex = Math.floor(index / 5);

      if (!acc[chunkIndex]) {
        acc[chunkIndex] = [];
      }

      // fetch the full object from localStorage with the key
      try {
        const property = JSON.parse(localStorageProxy.getItem(key));
        acc[chunkIndex].push({
          ...property,
          username: state.userId,
          [KEY_SYMBOL]: key
        });
      } catch (error) {
        this.$sentry?.captureException(error);
      }

      return acc;
    }, []);

    // Create a Generator that will yield chunks of recent properties
    function* recentPropertyGenerator () {
      while (chunkedRecentProperties.length) {
        yield chunkedRecentProperties.splice(0, 1)[0];
      }
    }

    // Loop over the chunks and save the recent property records
    for (const chunk of recentPropertyGenerator()) {
      await dispatch('saveRecentProperty', {
        property: chunk,
        requestTimer: new RequestTimer('save-stored-recent-properties', null, this.$route),
      });

      chunk.forEach(property => {
        // Remove the property from localStorage
        localStorageProxy.removeItem(property[KEY_SYMBOL]);
        // Remove the localStorage index
        const keyIndex = recentPropertyKeys.indexOf(property[KEY_SYMBOL]);
        if (keyIndex > -1) {
          // Remove the key from the index array
          recentPropertyKeys.splice(
            keyIndex,
            1
          );
          // Store the index again with the removed entry
          localStorageProxy.setItem(RECENT_PROPERTY_INDEX, JSON.stringify(recentPropertyKeys));
        }
      });
    }
  },
  /**
   * @function saveRecentProperty
   * @param {Object} context Vuex context
   * @param {Object} data RecentProperty data
   * Send the recent property data to the API
   */
  async saveRecentProperty (context, payload) {
    const { property, requestTimer } = payload;
    const service = useLegacyRecentPropertyService();
    return service.saveRecentProperty(property, requestTimer, this.$sentry);
  },

  /**
   * Store the recent property data into localStorage. We will store an index that holds an array
   * of keys. These keys are keys in local storage that contain the RPV data. We create an index,
   * rather than storing an array of RPV objects because we must json stringify local storage data.
   * The idea is it's faster to parse/stringify an array of keys, rather than an array of objects.
   * Especially when the stored RPVs get rather large.
   * @param {Object} context Vuex context
   * @param {Object} data RecentProperty data
   */
  storeRecentProperty (context, data) {
    const key = `${data.propertyListingId}-${Date.now()}`;
    // Set the item
    localStorageProxy.setItem(key, JSON.stringify(data));

    // Get or create an index
    const index = JSON.parse(localStorageProxy.getItem(RECENT_PROPERTY_INDEX)) ?? [];
    index.push(key);
    // Set the index of keys for easy retrieval and update
    localStorageProxy.setItem(RECENT_PROPERTY_INDEX, JSON.stringify(index));
  },

  async saveRecentCommunity({ dispatch, state }, { communityTree, listingTypeId }) {
    if (!communityTree?.leaf?.id) {
      return;
    }

    const data = {
      communityId: communityTree.leaf.id,
      listingTypeId,
      visitDate: new Date(),
    };

    if (state.user?.username) {
      await dispatch('createRecentCommunity', data);
    } else {
      const key = `community-${data.communityId}-${data.visitDate.getTime()}`;
      const index = localStorageProxy.getJSON(RECENT_COMMUNITY_INDEX) ?? [];
      localStorageProxy.setJSON(key, data);
      index.push(key);
      localStorageProxy.setJSON(RECENT_COMMUNITY_INDEX, index);
    }
  },

  async createRecentCommunity(_, data) {
    const service = useRecentCommunityService();
    const timer = new RequestTimer('store-user-createRecentCommunity');
    try {
      await service.createRecentCommunity(data, timer);
    } catch (err) {
      this.$sentry?.captureException(err);
    }
  },

  async saveStoredRecentCommunities({ dispatch, state }) {
    if (!state.user?.id) {
      return;
    }

    const index = localStorageProxy.getJSON(RECENT_COMMUNITY_INDEX) ?? [];

    function* recentCommunities() {
      while (index.length) {
        const key = index.shift();
        const data = localStorageProxy.getJSON(key);
        yield { key, data };
      }
    }

    for (const { key, data } of recentCommunities()) {
      await dispatch('createRecentCommunity', data);
      localStorageProxy.removeItem(key);
      localStorageProxy.setJSON(RECENT_COMMUNITY_INDEX, index);
    }
  },
}
