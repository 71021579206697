/**
 * Community Type constants
 * @module communityTypes
 */

export const communityTypes = {
 alias: 'alias',
 apartment: 'apartment',
 building: 'building',
 city: 'city',
 condominium: 'condominium',
 county: 'county',
 development: 'development',
 lsad57: 'lsad-57',
 lsadCg: 'lsad-cg',
 lsadUg: 'lsad-ug',
 macroNeighborhood: 'macro-neighborhood',
 neighborhood: 'neighborhood',
 residential: 'residential',
 scratch: 'scratch',
 state: 'state',
 subdivision: 'subdivision',
 subNeighborhood: 'sub-neighborhood',
 townhouse: 'townhouse',
 township: 'township',
 zipcode: 'zipcode'
};

export const communityTypeParentChildMap = {
  root: [communityTypes.state],
  state: [communityTypes.city],
  city: [
    communityTypes.neighborhood,
    communityTypes.development,
    communityTypes.condominium,
    communityTypes.townhouse,
    communityTypes.residential,
    communityTypes.subdivision,
    communityTypes.apartment,
    communityTypes.building
  ],
  nc: [
    communityTypes.development,
    communityTypes.condominium,
    communityTypes.townhouse,
    communityTypes.residential,
    communityTypes.subdivision,
    communityTypes.apartment,
    communityTypes.building
  ]
};

/**
 * The names of each section of the canoncial path. Each
 * section may be mapped to one or many communityTypes.
 *
 * @see rcs
 */
export const communityTreeLevels = {
  root: 'root',
  state: 'state',
  city: 'city',
  nc: 'nc',
  rc: 'rc',
  zipcode: 'zipcode'
};

/**
 * Array of communityTypes that are categorized as RCs.
 *
 * NOTE: the source of what constitutes an RC is from
 * feed_target.public.get_community_full_tree function
 */
export const rcs = [
  communityTypes.apartment,
  communityTypes.building,
  communityTypes.condominium,
  communityTypes.development,
  communityTypes.residential,
  communityTypes.subdivision,
  communityTypes.townhouse
];

/**
 * Returns Boolean for communityType requested
 * Typically used to show correct header text
 * for 'condominium' and 'townhouse' communities
 *
 * @param {String} communityTreeLeafId communityTree.leaf.communityTypeId
 * @param {Array} activePropertyTypes communityFilters.activePropertyTypes on the page for actives only
 * @param {Array} propertyTypeFilters communityFilters.propertyTypes for communities with only sold listings
 * @param {String} communityType Manually added by Dev for targeted community type
 * @param {String} oppositeCommunityType Manually added by Dev.
 * @example of oppositeCommunityType: checks if "condo" is only property type, the communityTypeId isn't labelled as "townhouse"
 */
export function isCommunityType( communityTreeLeafId, activePropertyTypes = [ ], propertyTypeFilters = [ ], communityType, oppositeCommunityType) {
  const leafEqualsCommunityType = communityTreeLeafId === communityType;
  const activePropertyEqualsCommunityType = (activePropertyTypes.length === 1 && activePropertyTypes[0] === communityType);
  const soldPropertyEqualsCommunityType = propertyTypeFilters.length === 1 && propertyTypeFilters[0] === communityType;
  const pageTypeNotMislabeled = communityTreeLeafId !== oppositeCommunityType;

  return (leafEqualsCommunityType || activePropertyEqualsCommunityType || soldPropertyEqualsCommunityType) && pageTypeNotMislabeled;
}
