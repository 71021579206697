import { event } from 'vue-gtag';
import { bexrealtyComPageTypes, eventNames } from '~/utils/analytics'
import { routeNames } from '~/utils/route-names';

/**
 * Categorizes a route into bexrealtycom page type @see {@link bexrealtyComPageTypes}.
 *
 * This allows us to place events onto a type of page in which the event occurred. In GA, we can
 * query things like: how many login events happened on a community vs a listing page?
 * @param {Object} route nuxt route
 */
export function getBexrealtyComPageType(route) {
  try {
    const { name } = route;

    let pageType;

    switch(name) {
      case routeNames.stateCity:
      case routeNames.stateCityNc:
      case routeNames.stateCityNcRc:
      case routeNames.stateCityMap:
      case routeNames.stateCityNcMap:
      case routeNames.stateCityNcRcMap:
        pageType = bexrealtyComPageTypes.community;
        break;
      case routeNames.stateCityRentals:
      case routeNames.stateCityNcRentals:
      case routeNames.stateCityNcRcRentals:
      case routeNames.stateCityMapRentals:
      case routeNames.stateCityNcMapRentals:
      case routeNames.stateCityNcRcMapRentals:
        pageType = bexrealtyComPageTypes.communityRental;
        break;
      case routeNames.stateCityComps:
      case routeNames.stateCityNcComps:
      case routeNames.stateCityNcRcComps:
        pageType = bexrealtyComPageTypes.comp;
        break;
      case routeNames.sitemaps:
      case routeNames.sitemapsCity:
      case routeNames.sitemapsNc:
      case routeNames.sitemapsState:
      case routeNames.sitemapsStateNewListings:
        pageType = bexrealtyComPageTypes.htmlSitemap;
        break;
      case routeNames.sitemapsStateNewRentalListings:
        pageType = bexrealtyComPageTypes.htmlSitemapRental;
        break;
      case routeNames.home:
        pageType = bexrealtyComPageTypes.home;
        break;
      case routeNames.stateCityAddressAgricultural:
      case routeNames.stateCityAddressApartment:
      case routeNames.stateCityAddressBoatSlip:
      case routeNames.stateCityAddressCondominium:
      case routeNames.stateCityAddressDeededParking:
      case routeNames.stateCityAddressHome:
      case routeNames.stateCityAddressHouseboat:
      case routeNames.stateCityAddressLand:
      case routeNames.stateCityAddressMultiFamilyHome:
      case routeNames.stateCityAddressSingleFamilyHome:
      case routeNames.stateCityAddressTownhouse:
      case routeNames.stateCityAddressVilla:
        pageType = bexrealtyComPageTypes.listing;
        break;
      case routeNames.stateCityAddressAgriculturalRental:
      case routeNames.stateCityAddressApartmentRental:
      case routeNames.stateCityAddressCondominiumRental:
      case routeNames.stateCityAddressHomeRental:
      case routeNames.stateCityAddressHouseboatRental:
      case routeNames.stateCityAddressLandRental:
      case routeNames.stateCityAddressMultiFamilyHomeRental:
      case routeNames.stateCityAddressSingleFamilyHomeRental:
      case routeNames.stateCityAddressTownhouseRental:
      case routeNames.stateCityAddressVillaRental:
        pageType = bexrealtyComPageTypes.listingRental;
        break;
      case routeNames.resources:
      case routeNames.resourcesCalculators:
      case routeNames.resourcesGetPreApproved:
      case routeNames.resourcesInsuranceRates:
      case routeNames.resourcesMortgageRates:
        pageType = bexrealtyComPageTypes.resource;
        break;
      case routeNames.state:
        pageType = bexrealtyComPageTypes.state;
        break;
      case routeNames.stateRentals:
        pageType = bexrealtyComPageTypes.stateRental;
        break;
      case routeNames.zipcode:
      case routeNames.zipcodeZip:
        pageType = bexrealtyComPageTypes.zip;
        break;
      case routeNames.zipcodeRentals:
      case routeNames.zipcodeZipRentals:
        pageType = bexrealtyComPageTypes.zipRental;
        break;
      default:
        pageType = bexrealtyComPageTypes.other;
    }

    return pageType;
  } catch {
    return null;
  }
}

/**
 * Get an object that represents a page view.
 *
 * At the time of writing, by defualt vue-gtag with nuxt sends the to.name of the route instead of
 * the document.title of the page. So we override this behavior. Also send our `bex-page-type`
 * custom event parameter
 * @param {Object} route
 */
export function getPageTrackerTemplate(route) {
  const bexPageType = getBexrealtyComPageType(route);
  const pageTitle = document?.title ?? route.name;
  const pageLocation = window?.location?.href ?? route.fullPath;

  return {
    // custom page property that categorizes the current page
    bex_page_type: bexPageType,

    // standard GA4 page view event properties
    page_location: pageLocation,
    page_path: route.path,
    page_title: pageTitle,
  };
}

/**
 * Send a custom ad event to analytics
 *
 * @param {Object} $route nuxt route
 * @param {Object} eventParameters Event params to send with this event
 * @param {String} eventParameters.id Unique identifier for this ad
 * @param {actionTypes} eventParameters.actionType The action when interacted with this ad
 * @param {Number} eventParameters.rank The configured priority rank of the ad
 * @param {Number} eventParameters.spot The 1 based index of the ad, in a list of ads, not to be
 * confused with rank. Rank is the configured priority of the ad, spot is the actual position in
 * the list. Rank does not necessarily equal spot.
 */
export function sendAdEvent($route, eventParameters) {
  const bexPageType = getBexrealtyComPageType($route);
  const { id, actionType, rank, spot } = eventParameters;

  event(eventNames.directAd, {
    ad_id: id,
    bex_action_type: actionType,
    bex_page_type: bexPageType,
    ad_rank: rank,
    ad_spot: spot,
  });
}

/**
 * Send a custom CTA event to analytics
 * @param {Object} $route nuxt route
 * @param {Object} eventParameters Event params to send with this event
 * @param {ctaIds} eventParameters.id Unique identifier for this cta event
 * @param {actionTypes} eventParameters.actionType The action when interacted with this ad
 */
export function sendCtaEvent($route, eventParameters) {
  const bexPageType = getBexrealtyComPageType($route);
  const { id, actionType } = eventParameters;

  event(eventNames.cta, {
    cta_id: id,
    bex_action_type: actionType,
    bex_page_type: bexPageType,
  });
}

/**
 * Send recommended login event to analytics.
 *
 * {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=10740341448008168483-NA&client_type=gtag#login GA Docs}
 * @param {Object} eventParameters Event params to send with this event
 * @param {authMethods} eventParameters.method The auth method used
 */
export function sendLoginEvent(eventParameters) {
  const { method } = eventParameters;
  event(eventNames.login, {
    method: method,
  });
}

/**
 * Send recommended search event to analytics.
 *
 * {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=2222941850834591086-NA&client_type=gtag#search GA Docs}
 * @param {Object} eventParameters
 * @param {String} eventParameters.keyword
 */
export function sendSearchEvent(eventParameters) {
  const { keyword } = eventParameters;
  let trimmedKeyword = keyword;

  // trim keyword in hopes of reducing cardinality of search terms
  if (typeof keyword === 'string') {
    trimmedKeyword = keyword.trim();
  }

  event(eventNames.search, {
    search_term: trimmedKeyword,
  });
}

/**
 * Send recommended sign up event to analytics.
 *
 * {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=10740341448008168483-NA&client_type=gtag#sign_up GA Docs}
 * @param {Object} eventParameters Event params to send with this event
 * @param {authMethods} eventParameters.method The auth method used
 */
export function sendSignUpEvent(eventParameters) {
  const { method } = eventParameters;
  event(eventNames.signUp, {
    method: method,
  });
}
